import React from "react";
import { useParams } from "react-router-dom";
import Logo from "../../../images/onbordinglogo.png";
import Contact from "../../../images/onbording-contact-icon.svg";
import { ArrowRightShort } from "react-bootstrap-icons";
import useUserForm from "../../hooks/onbording/user/new/useStateFile";
import useLocalStorage from "../../hooks/onbording/user/new/useLocalStorage";
import {
  deliveryTypeOption,
  posOption,
  preparationTime,
} from "../../selectOption";
import submitData from "../../hooks/onbording/user/new/submitData";

const FormDesktop = ({ setShowForm, siteName }) => {
  const [formData, setFormData, formDataLa, setFormDataLa,  errors, validateForm, validateFormForMobile, validateFormLa, validateFormForMobileLa] = useUserForm();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorage("formData");

  let decodedValue = "";
  let convertedText = "";

  // Get the current URL
  const currentUrl = window.location.href;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("root")) {
    const encodedValue = currentUrl.split("=")[1];

    // Decode the base64-encoded value
    decodedValue = atob(decodeURIComponent(encodedValue));
    convertedText = decodedValue.split("-").join(" ");

    // console.log("decodedValue-----------", decodedValue);
  } else {
    decodedValue = "";
    // console.log("No 'root' parameter in the URL.");
  }

  let url = `https://restogpt.ai/${decodedValue}`;

  React.useEffect(() => {
    localStorage.setItem("siteName", siteName);
  }, []);

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    // console.log("lastFilledComponent", lastFilledComponent);
    if (lastFilledComponent) {
      setFormData({
        name: lastFilledComponent.name || "",
        email: lastFilledComponent.email || "",
        restaurantNumber: lastFilledComponent.restaurantNumber || "",
        restaurantAddress1: lastFilledComponent.restaurantAddress1 || "",
        restaurantAddress2: lastFilledComponent.restaurantAddress2 || "",
        pin: lastFilledComponent.pin || "",
        restaurantWebsite: lastFilledComponent.restaurantWebsite || "",
        deliveryType: lastFilledComponent.deliveryType || "",
        posState: lastFilledComponent.posState || "",
        otherPosState: lastFilledComponent.otherPosState || "",
        preparationTime: lastFilledComponent.preparationTime || "",
      });
    }
  }, [setFormData]);

  const selectStyle = {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#FFA500", // Change hover color
  };

  const handleInputChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    handleInputChangeWithStorageUpdate({
      target: { name, value: numericValue },
    });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (validateForm(siteName)) {
      submitData(formData, siteName, decodedValue, convertedText);
      localStorage.clear();
      setShowForm("thanks");
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };
  return (
    <>
      <div className="right-container">
        <div className="right-header">
          <a href="https://getorderbyte.com/" className="logo">
            <img src={Logo} />
          </a>
          <a href="mailto:hello@restogpt.ai">
            <span className="icon">
              <img src={Contact} />
            </span>
          </a>
        </div>
        {decodedValue !== "" && (
          <div className="right-header-text">
            {/* <p className="right-header-text-p">{convertedText}</p> */}
            <a href={url} target="_blank">{url}</a>
          </div>
        )}
        <div className="right-wrapper">
          <form class="styled-form">
            <div class="form-group">
              <label htmlFor="name">
                1<ArrowRightShort />
                Your full name<span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="name"
                name="name"
                placeholder="e.g. Robert Cooperman"
                value={formData.name}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.name}</span>
            </div>
            <div class="form-group">
              <label htmlFor="email">
                2<ArrowRightShort />
                Your email<span className="impt">*</span>
              </label>
              <input
                type="email"
                className="input-form"
                id="email"
                name="email"
                placeholder="stellas@gmail.com"
                value={formData.email}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.email}</span>
            </div>
            {siteName === "lacanada" ? (
              <>
                <div class="form-group">
                  <label htmlFor="restaurantNumber">
                    3<ArrowRightShort />
                    Your phone number<span className="impt">*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    id="restaurantNumber"
                    name="restaurantNumber"
                    placeholder="e.g. 123456789"
                    value={formData.restaurantNumber}
                    onInput={handleNumberInputChange}
                    required
                  />
                  <span className="error">{errors.restaurantNumber}</span>
                </div>
                <div class="form-group">
                  <label htmlFor="restaurantName">
                    4<ArrowRightShort />
                    Restaurant name<span className="impt">*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    id="restaurantName"
                    name="restaurantName"
                    placeholder="e.g. Stella’s Pizza"
                    value={formData.restaurantName}
                    onInput={handleNumberInputChange}
                    required
                  />
                  <span className="error">{errors.restaurantName}</span>
                </div>
              </>
            ) : (
              <div class="form-group">
                <label htmlFor="restaurantNumber">
                  3<ArrowRightShort />
                  Your phone number<span className="impt">*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  id="restaurantNumber"
                  name="restaurantNumber"
                  placeholder="e.g. 123456789"
                  value={formData.restaurantNumber}
                  onInput={handleNumberInputChange}
                  required
                />
                <span className="error">{errors.restaurantNumber}</span>
              </div>
            )}
            <div class="form-group">
              <label htmlFor="restaurant-address">
                4<ArrowRightShort />
                Restaurant address<span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="restaurantAddress1"
                name="restaurantAddress1"
                placeholder="e.g. 1010 Wilshire Blvd"
                value={formData.restaurantAddress1}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.restaurantAddress1}</span>
              <div className="form-address">
                <div className="form-address-content">
                  <input
                    type="text"
                    className="input-form"
                    id="restaurantAddress2"
                    name="restaurantAddress2"
                    placeholder="e.g. Los Angeles, CA"
                    value={formData.restaurantAddress2}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.restaurantAddress2}</span>
                </div>
                <div className="form-address-content">
                  <input
                    type="text"
                    className="input-form"
                    id="pin"
                    name="pin"
                    placeholder="e.g. 90017"
                    value={formData.pin}
                    onInput={handleNumberInputChange}
                    required
                  />
                  <span className="error">{errors.pin}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label htmlFor="restaurantWebsite">
                5<ArrowRightShort />
                Restaurant website<span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="restaurantWebsite"
                name="restaurantWebsite"
                placeholder="e.g. www.stellas.com"
                value={formData.restaurantWebsite}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.restaurantWebsite}</span>
            </div>
            {siteName === "lacanada" ? (
              <>
                <div class="form-group">
                  <label htmlFor="deliveryType">
                    6<ArrowRightShort />
                    Order preparation time
                    <span className="impt">*</span>
                  </label>
                  <select
                    className="input-form"
                    id="preparationTime"
                    name="preparationTime"
                    onChange={handleInputChangeWithStorageUpdate}
                    value={formData.preparationTime}
                    // style={selectStyle}
                  >
                    <option value="" style={{ color: "#C8BFE5" }}>
                      Please select
                    </option>
                    {preparationTime.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  <span className="error">{errors.preparationTime}</span>
                </div>
              </>
            ) : (
              <>
                <div class="form-group">
                  <label htmlFor="deliveryType">
                    6<ArrowRightShort />
                    What delivery type does your restaurant want to use?
                    <span className="impt">*</span>
                  </label>
                  <select
                    className="input-form"
                    id="deliveryType"
                    name="deliveryType"
                    onChange={handleInputChangeWithStorageUpdate}
                    value={formData.deliveryType}
                    // style={selectStyle}
                  >
                    <option value="" style={{ color: "#C8BFE5" }}>
                      Please select
                    </option>
                    {deliveryTypeOption.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  <span className="error">{errors.deliveryTypeState}</span>
                </div>
                <div class="form-group">
                  <label htmlFor="posState">
                    7<ArrowRightShort />
                    Which POS system does your restaurant use?<span className="impt">*</span>
                  </label>
                  <select
                    className="input-form"
                    id="posState"
                    name="posState"
                    onChange={handleInputChangeWithStorageUpdate}
                    value={formData.posState}
                  >
                    <option value="">Please select</option>
                    {posOption.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  <span className="error">{errors.posState}</span>
                </div>
                {formData.posState === "Other" ? (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        className="input-form"
                        id="otherPosState"
                        name="otherPosState"
                        placeholder="enter POS type"
                        value={formData.otherPosState}
                        onChange={handleInputChangeWithStorageUpdate}
                        required
                      />
                      <span className="error">{errors.otherPosState}</span>
                    </div>
                  </>
                ) : null}
              </>
            )}

            <div className="btn-form-container">
              <button className="form-btn" onClick={handleSubmitData}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormDesktop;
