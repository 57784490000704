import React, { useState } from "react";
import InputHints from "react-input-hints";
import { ArrowRightShort } from "react-bootstrap-icons";
import UseOnly from '../images/us-only.svg';

const ResMenu = ({
  restaurantMenu,
  setRestaurantMenu,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
  showCircles,
}) => {
  React.useEffect(() => {
    var restaurantMenuFocus = document.getElementById("restaurantMenu");
    restaurantMenuFocus.focus();
  }, []);
  return (
    <>
      <div className="form-box">
        <p>
          1<ArrowRightShort />
          {/* 3<ArrowRightShort /> */}
          YOUR MENU [URL] LINK
          <span>*</span> <img src={UseOnly}/>
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
        {showCircles && <div className="input-circle"></div>} 
        <InputHints
          type="text"
          id="restaurantMenu"
          placeholders={["e.g. www.stellaspizza.com/menu"]}
          className="form-control"
          value={restaurantMenu}
          onChange={(val) => {
            setRestaurantMenu(val.target.value);
            setErrorMessage("");
          }}
          onKeyPress={(e) => handleKeypress(e,current,next)}
        />
        </form>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default ResMenu;
