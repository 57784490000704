import React from "react";
// import Logo from "../../images/logo.png";
import Logo from "../../../images/onbordinglogo.png";
import Contact from "../../../images/onbording-contact-icon.svg";

const About = ({ setShowForm }) => {
  const handleShowForm = () => {
    setShowForm("form");
  };
  return (
    <>
      <div className="right-container">
        <div className="right-header">
          <a href="https://getorderbyte.com/" className="logo">
            <img src={Logo} />
          </a>
          <a href="mailto:hello@restogpt-ai.com">
            <span className="icon">
              <img src={Contact} />
            </span>
          </a>
        </div>
        <div className="right-wrapper">
          <div className="right-content">
            <h3>
              What's <span>Next?</span>
            </h3>
            <p>Get ready to launch in 24 hours</p>
            <div className="stepper">
              <div class="step">
                <span class="step-number">1</span>
                <div class="step-content">
                  Fill out the restaurant details form
                </div>
              </div>
              <div class="divider"></div>
              <div class="step">
                <span class="step-number">2</span>
                <div class="step-content">
                  Join your personal support chat for final <br />
                  updates
                </div>
              </div>
              <div class="divider"></div>
              <div class="step">
                <span class="step-number">3</span>
                <div class="step-content">
                  Time to launch your AI delivery storefront!
                </div>
              </div>
            </div>
            <button className="btn" onClick={handleShowForm}>
              Let’s Get Started {">"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
