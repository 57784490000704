import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import CloseIcon from "../../images/close-icon.png";
import last1 from "../../images/popup/lastmile/last1.png";
import last2 from "../../images/popup/lastmile/last2.png";
import last3 from "../../images/popup/lastmile/last3.png";
import last4 from "../../images/popup/lastmile/last4.png";
import last5 from "../../images/popup/lastmile/last5.png";
import last6 from "../../images/popup/lastmile/last6.png";
import last7 from "../../images/popup/lastmile/last7.png";
import last8 from "../../images/popup/lastmile/last8.png";
import last9 from "../../images/popup/lastmile/last9.png";

function StorefrontModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="more-container">
        <a className="btun" onClick={handleShow}>
          LEARN MORE
        </a>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="AI-modal lastmile"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <Row>
            <Col lg="6" sm="12">
              <div className="item">
                <img src={last1} />
                <p>AI dispatch</p>
              </div>
              <div className="item">
                <img src={last2} />
                <p>Own driver app</p>
              </div>
              <div className="item">
                <img src={last3} />
                <p>Hybrid delivery</p>
              </div>
              <div className="item">
                <img src={last4} />
                <p>On-demand drivers</p>
              </div>
              <div className="item">
                <img src={last5} />
                <p>Driver notifications</p>
              </div>
            </Col>
            <Col lg="6" sm="12">
            <div className="item">
                <img src={last6} />
                <p>Flexible pricing options and splits <br /><span style={{ color: "#5a00ca" }}>[with customer]</span></p>
              </div>
              <div className="item">
                <img src={last7} />
                <p>Delivery area zoning</p>
              </div>
              <div className="item">
                <img src={last8} />
                <p>Delivery price logic <br /><span style={{ color: "#5a00ca" }}>[fixed, %percent, per mile]</span></p>
              </div>
              <div className="item">
                <img src={last9} />
                <p>Reporting</p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StorefrontModal;
