import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

let pubKeyTest = process.env.REACT_APP_STRIP_PUB_KEY_TEST;
let pubKeyLive = process.env.REACT_APP_STRIP_PUB_KEY_LIVE;

// const stripePromise = loadStripe(pubKeyTest);
const stripePromise = loadStripe(pubKeyLive);

const Index = () => {
  const [clientSecret, setClientSecret] = useState("");
  let formDataOne = JSON.parse(localStorage.getItem("formDataOne"));

  useEffect(() => {
    let clientSecretId = localStorage.getItem("clientSecret");
    setClientSecret(clientSecretId);
  }, []);

  const appearance = {
    theme: "stripe",
    labels: "floating",
    variables: {
      colorPrimary: "#262626",
      colorBackground: "#9B6AFE",
      colorText: "#fff",
      colorDanger: "#df1b41",
      fontFamily: "SF UI Display",
      spacingUnit: "5px",
      borderRadius: "4px",
      fontSizeBase: "18px",
      // See all possible variables below
    },
  };
  const options = {
    clientSecret,
    appearance,
    business: "RocketRides",
  };
  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default Index;
