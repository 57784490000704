import React, { useEffect, useState } from "react";
import useWindowResize from "./hooks/onbording/static/useWindowResize";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import Left from "./components/left";
import NonDisclosure from "./components/nonDisclosure";
import MobileCompany from "./components/mobileCompany";
import MobileNonDisclosure from "./components/mobileCompany/mobileNonDisclosure";
import "./App.css";

const NonDisclouser = () => {
  const { screenWidth } = useWindowResize();

  return (
    <>
    <Helmet>
        <title>RestoGPT AI: Launch in 24 hours</title>
      </Helmet>
      {screenWidth > 992 ? (
        <div className="wraper">
          <Container fluid>
            <Row>
              <Col className="p-0" lg="6">
                <Left />
              </Col>
              <Col className="p-0" lg="6">
                <NonDisclosure />
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <MobileNonDisclosure />
      )}
    </>
  );
};

export default NonDisclouser;
