import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import FooterLogo from "../images/footer-logo.png";

const Index = () => {
  return (
    <>
      <div className="footer">
        <Container>
          <Row>
            <Col lg="12">
              <div className="footer-box">
                <div className="footer-group">
                  <img src={FooterLogo} />
                  <p className="bottom-footer-text">
                    Copyright © {new Date().getFullYear()}. All rights reserved
                    by RestoGPT
                  </p>
                </div>
                <div className="footer-group">
                  <p>
                    <a href="mailto:hello@restogpt-ai.com">
                      HELLO@RESTOGPT-AI.COM
                    </a>
                  </p>
                  <p className="bottom-footer-text">Los Angeles, CA</p>
                </div>
                <div className="footer-group">
                  <p >
                    <a href="news">NEWS AND PRESS</a>
                  </p>
                  <p className="bottom-footer-text" style={{visibility: "hidden", display: "block"}}>
                    <a href="blog">BLOG</a>
                  </p>
                </div>
                <div className="footer-group">
                  <p>
                    <a href="term-and-condition" target="_blank">TERMS AND CONDITIONS</a>
                  </p>
                  <p className="bottom-footer-text">
                    <a href="privacy-policy" target="_blank">PRIVACY POLICY</a>
                  </p>
                </div>
                {/* <p>
                  <a href="mailto:hello@restogpt-ai.com">
                    HELLO@RESTOGPT-AI.COM
                  </a>
                </p> */}
              </div>
            </Col>
            {/* <Col lg="12">
              <div className="bottom-footer-box">
                <p className="bottom-footer-text">Los Angeles, CA</p>
                <p className="bottom-footer-text">
                  Copyright © {new Date().getFullYear()}. All rights reserved by
                  RestoGPT
                </p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
