import { useState } from "react";

const useUserForm = () => {
  // let siteName = localStorage.getItem("siteName");
  // console.log("siteName form ",siteName);
  const [formData, setFormData] = useState({
    restaurantName: "",
    restaurantType: "",
    restaurantWebsite: "",
    restaurantAddress: "",
    color: "",
  });

  const [errors, setErrors] = useState({
    restaurantName: "",
    restaurantType: "",
    restaurantWebsite: "",
    restaurantAddress: "",
    color: "",
  });

  const validateForm = (siteName) => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.restaurantName) {
      valid = false;
      newErrors.restaurantName = "Restaurant name is required";
    } else {
      newErrors.restaurantName = "";
    }

    if (!formData.restaurantType) {
        valid = false;
        newErrors.restaurantType = "Restaurant type is required";
      } else {
        newErrors.restaurantType = "";
      }

    if (!formData.restaurantWebsite) {
        valid = false;
        newErrors.restaurantWebsite = "Restaurant menu link is required";
      } else {
        newErrors.restaurantWebsite = "";
      }

    if (!formData.restaurantAddress) {
      valid = false;
      newErrors.restaurantAddress = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress = "";
    }

    if (!formData.color) {
        valid = false;
        newErrors.color = "Color is required";
      } else {
        newErrors.color = "";
      }

    setErrors(newErrors);
    // console.log("Is form valid?", newErrors);
    return valid;
  };

//   const validateFormForMobile = (current, siteName) => {
//     let valid = true;
//     const newErrorsForMobile = { ...errors };
//     switch (current) {
//       case "name":
//         if (!formData.name) {
//           valid = false;
//           newErrorsForMobile.name = "Name is required";
//         } else {
//           newErrorsForMobile.name = "";
//         }
//         break;

//       case "email":
//         if (!formData.email) {
//           valid = false;
//           newErrorsForMobile.email = "Email is required";
//         } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//           valid = false;
//           newErrorsForMobile.email = "Invalid email format";
//         } else {
//           newErrorsForMobile.email = "";
//         }
//         break;

//       case "restaurantName":
//         if (!formData.restaurantName) {
//           valid = false;
//           newErrorsForMobile.restaurantName = "Restaurant name is required";
//         } else {
//           newErrorsForMobile.restaurantName = "";
//         }
//         break;

//       case "restaurantAddress1":
//         if (!formData.restaurantAddress1) {
//           valid = false;
//           newErrorsForMobile.restaurantAddress1 =
//             "Restaurant address is required";
//         } else if (!formData.restaurantAddress2) {
//           valid = false;
//           newErrorsForMobile.restaurantAddress2 =
//             "Restaurant address is required";
//         } else if (!formData.pin) {
//           valid = false;
//           newErrorsForMobile.pin = "Zip code is required";
//         } else if (!/^\d+$/.test(formData.pin)) {
//           valid = false;
//           newErrorsForMobile.pin = "Zip code must contain only numbers";
//         } else {
//           newErrorsForMobile.restaurantAddress1 = "";
//         }
//         break;

//       case "restaurantWebsite":
//         if (!formData.restaurantWebsite) {
//           valid = false;
//           newErrorsForMobile.restaurantWebsite =
//             "Restaurant website is required";
//         } else {
//           newErrorsForMobile.restaurantWebsite = "";
//         }
//         break;

//       case "deliveryType":
//         if (siteName === "welcome") {
//           if (!formData.deliveryType) {
//             valid = false;
//             newErrorsForMobile.deliveryTypeState = "Select delivery type";
//           } else {
//             newErrorsForMobile.deliveryTypeState = "";
//           }
//         } else {
//           newErrorsForMobile.deliveryTypeState = "";
//         }
//         break;

//       case "posState":
//         if (siteName === "welcome") {
//           if (!formData.posState) {
//             valid = false;
//             newErrorsForMobile.posState = "Select POS system";
//           } else {
//             newErrorsForMobile.posState = "";
//           }

//           if (formData.posState === "Other") {
//             if (!formData.otherPosState) {
//               valid = false;
//               newErrorsForMobile.otherPosState = "POS system is required";
//             } else {
//               newErrorsForMobile.otherPosState = "";
//             }
//           } else {
//             newErrorsForMobile.otherPosState = "";
//           }
//         } else {
          
//         }
//         break;

//       case "preparationTime":
//         if (siteName === "lacanada") {
//           if (!formData.preparationTime) {
//             valid = false;
//             newErrorsForMobile.preparationTime =
//               "Order preparation time is required";
//           } else {
//             newErrorsForMobile.preparationTime = "";
//           }
//         }
//         break;
//       default:
//         break;
//     }
//     setErrors(newErrorsForMobile);
//     console.log("Is form valid?", newErrorsForMobile);
//     return valid;
//   };

  return [formData, setFormData, errors, validateForm];
};

export default useUserForm;
