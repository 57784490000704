// Loading.js (Create a new file for the Loading component)
import React from 'react';
import './loading.css'; // Add a CSS file for styling if needed

const Index = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default Index;
