import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Logo from "../images/logo.svg";
import BlogBannerImg from "../images/blogBanner.png";

const BlogHead = () => {
  return (
    <>
      <div className="header">
        <Navbar collapseOnSelect expand="lg">
          <Container fluid className="justify-content-between">
            <Navbar.Brand href="/" className="logo">
              <img src={Logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-center center"
            >
              <Nav className="menu">
                {/* <Nav.Link href="https://103.172.92.46/#FEATURES">FEATURES</Nav.Link>
                <Nav.Link href="https://103.172.92.46/#BENEFITS">BENEFITS</Nav.Link>
                <Nav.Link href="https://103.172.92.46/#CASESTUDIES">CASE STUDIES</Nav.Link>
                <Nav.Link href="https://103.172.92.46/#MODEL">MODEL</Nav.Link>
                <Nav.Link
                  href="https://103.172.92.46/#GENERATE"
                  className="gen-btn"
                  // onClick={handleFocus}
                >
                  GENERATE
                </Nav.Link> */}
                  <Nav.Link href="https://restogpt.ai/#WORKS">HOW IT WORKS</Nav.Link>
                  <Nav.Link href="https://restogpt.ai/#FEATURES">FEATURES</Nav.Link>
                  <Nav.Link href="https://restogpt.ai/#BENEFITS">BENEFITS</Nav.Link>
                  <Nav.Link href="https://restogpt.ai/#CASESTUDIES">CASE STUDIES</Nav.Link>
                  <Nav.Link href="https://restogpt.ai/#PRICE">PRICE</Nav.Link>
                  <Nav.Link
                    href="https://restogpt.ai/#GENERATE"
                    className="gen-btn"
                    // onClick={handleFocus}
                  >
                    GENERATE
                  </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="blog-banner">
        <img
          src={BlogBannerImg}
          alt="blog-banner"
          className="blog-banner-img"
        />
      </div>
      <div className="banner-footer">
        <h3>Restaurant AI x Ordering Blog</h3>
      </div>
    </>
  );
};

export default BlogHead;
