import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MailIcon from "../../../images/mail-icon.png";
import MLogo from "../../../images/mobile-logo.png";

const Index = ({ setShowPage }) => {
    const handleShowPage = () => {
        setShowPage('formPage') 
    }
  return (
    <>
      <div className="mobile-wraper">
        <Container>
          <Row>
            <Col lg="12">
              <div className="mobile-headerr">
                <a href="mailto:hello@restogpt-ai.com">
                  <img src={MailIcon} />
                </a>
              </div>
              <div className="mobile-logo">
                <a href="">
                  <img src={MLogo} />
                </a>
              </div>
              <div className="content">
                <h3>
                  Hey👋 <span>Welcome!</span>
                </h3>
                <button className="start-btn" onClick={handleShowPage}>Let’s Get Started &gt;</button>
                <div className="step-box">
                  <h4>
                    What's <span>Next?</span>
                  </h4>
                  <p>Get ready to launch in 24 hours</p>
                  <div className="list">
                    <div className="icon">1</div>
                    <p>Fill out the restaurant details form</p>
                  </div>
                  <div className="list">
                    <div className="icon">2</div>
                    <p>Join your personal support chat for final updates</p>
                  </div>
                  <div className="list">
                    <div className="icon">3</div>
                    <p>Time to launch your AI delivery storefront!</p>
                  </div>
                </div>
                <div className="ai-box">
                  <p>
                    Maximize orders <span>with AI</span>
                  </p>
                  <p>
                    Lower costs <span>with AI</span>
                  </p>
                  <p>
                    Make money <span>with AI</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
