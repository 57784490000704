import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/homePage";
import NewHomePage from "./pages/newHomePage";
import OneForm from "./onbording/OneForm";
import Checkout from "./onbording/components/stripe/index";
import Buffer from "./onbording/components/thankyou/buffer";
import ThankYou from "./onbording/components/thankyou/index";
import Onboarding from "./onbording/OnbordingPage";
import InternalForm from "./onbording/InternalForm";
import DeliveryPrefered from "./onbording/DeliveryPrefered";
import NonDisclouser from "./onbording/NonDisclouser";
import NotFound from "./component/404/NotFound";
import PrivacyPolicy from './privacyPolicy/index';
import TermAndCondition from './termAndCondition/index';
import Blog from './blog/index';
import BlogDetail from './blog/blogDetail/index';
import News from "./news/index";
import NewsDetail from './news/newsDetail/index';
import "./fonts/fonts.css";
import "./App.css";

function App() {
  return (
    <>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/welcome" element={<Onboarding/>} />
          <Redirect from="/en" to="/" />
          <Route component={NotFound} />
        </Routes>
      </BrowserRouter> */}

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/production" element={<NewHomePage />} /> */}
          <Route path="/welcome" element={<OneForm siteName="welcome" />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/buffer" element={<Buffer />} />
          <Route path="/thank-you" element={<ThankYou />} />
          {/* <Route path="/welcome" element={<Onboarding siteName="welcome" />} /> */}
          <Route path="/lacanada" element={<Onboarding siteName="lacanada" />} />
          <Route path="/preference" element={<DeliveryPrefered siteName="form" />} />
          <Route path="/internal" element={<InternalForm siteName="form" />} />
          <Route path="/non-disclosure" element={<NonDisclouser  />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-and-condition" element={<TermAndCondition />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail/:id/:params" element={<BlogDetail />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-detail/:id" element={<NewsDetail />} />
          <Route path="/en" element={<Navigate to="/" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
