import React, { useEffect, useState } from "react";
import useWindowResize from "./hooks/onbording/static/useWindowResize";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import Left from "./components/left";
import InternalRight from "./components/internalRight/index";
import Mobile from "./components/mobile";
import "./App.css";

const OnbordingPage = ({ siteName }) => {
  const { screenWidth } = useWindowResize();

  return (
    <>
    <Helmet>
        <title>RestoGPT AI: Launch in 24 hours</title>
      </Helmet>
      {screenWidth > 992 ? (
        <div className="wraper">
          <Container fluid>
            <Row>
              {/* <Col className="p-0" lg="6">
                <Left />
              </Col> */}
              <Col className="p-0" lg="12">
                <InternalRight siteName={siteName}/>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Mobile siteName={siteName}/>
      )}
    </>
  );
};

export default OnbordingPage;
