export const newsData = [
    {
        id: 1,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 1",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
    {
        id: 2,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 2",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
    {
        id: 3,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 3",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
    {
        id: 4,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 4",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
    {
        id: 5,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 5",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
    {
        id: 6,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 6",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
    {
        id: 7,
        title: "RestoGPT Crafts Partnership With Swappa Used Tech Platform 7",
        created_at : "FEBRUARY 9, 2022",
        publisher_name: "BY name of publisher",
        description: "Article  text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them. Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them.  Summary text: let's explore the challenges of optimizing your convenience store's alcohol inventory for delivery and how to overcome them."
    },
]