import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import { Modal, Row, Col, Button } from "react-bootstrap";
import CloseIcon from "../../../images/close-icon.png";
import MobileOrdering1 from "../../../images/mobileOrdering-1.png";
import MobileOrdering2 from "../../../images/mobileOrdering-2.png";

function nonDisclosureModal({ open, handleClose }) {
  return (
    <>
      {/* <a className="btun"  onClick={handleShow}>LEARN MORE</a> */}

      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        centered
        className="AI-modal-disclosure"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <img src={CloseIcon} />
          </Button>
          <Row>
            <Col lg="12">
              <div className="item" style={{ marginBottom: "50px" }}>
                <div className="disclosure-title">
                  <h4>MUTUAL NONDISCLOSURE AGREEMENT</h4>
                </div>
                <div style={{ height: "500px", overflowY: "auto" }}>
                  <p className="right-disclosure-text">
                    The party submitting the online form (details of party in
                    the corresponding form responses), which they can only do by
                    accepting the terms of this mutual NDA (the “Receiving
                    Party”) understands that CloudBrands, Inc., d/b/a RestoGPT
                    AI, a Delaware Corporation at 2055 Limestone Rd Ste 200-C
                    Wilmington, DE 19808 (the “Disclosing Party”) has disclosed
                    or may disclose information relating to the Disclosing
                    Party’s business (including, without limitation, computer
                    programs, technical drawings, algorithms, know-how,
                    formulas, processes, ideas, inventions (whether patentable
                    or not), schematics and other technical, business,
                    financial, customer and product development plans,
                    forecasts, strategies and information), which to the extent
                    previously, presently or subsequently disclosed to the
                    Receiving Party is hereinafter referred to as “Proprietary
                    Information” of the Disclosing Party.
                  </p>
                  <p className="right-disclosure-text">
                    In consideration of the parties’ discussions and any access
                    of the Receiving Party to Proprietary Information of the
                    Disclosing Party, the Receiving Party hereby agrees as
                    follows:
                  </p>
                  <p className="right-disclosure-text">
                    1. The Receiving Party agrees (i) to hold the Disclosing
                    Party’s Proprietary Information in confidence and to take
                    reasonable precautions to protect such Proprietary
                    Information (including, without limitation, all precautions
                    the Receiving Party employs with respect to its own
                    confidential materials), (ii) not to divulge any such
                    Proprietary Information or any information derived therefrom
                    to any third person, (iii) not to make any use whatsoever at
                    any time of such Proprietary Information except to evaluate
                    internally its relationship with the Disclosing Party, (iv)
                    not to copy or reverse engineer any such Proprietary
                    Information and (v) not to export or reexport (within the
                    meaning of U.S. or other export control laws or regulations)
                    any such Proprietary Information or product thereof. If the
                    receiving party is an organization, then the Receiving Party
                    also agrees that, even within Receiving Party, Proprietary
                    Information will be disseminated only to those employees,
                    officers and directors with a clear and well-defined “need
                    to know” for purposes of the business relationship between
                    the parties. Without granting any right or license, the
                    Disclosing Party agrees that the foregoing shall not apply
                    with respect to any information after five years following
                    the disclosure thereof or any information that the Receiving
                    Party can document (i) is or becomes (through no improper
                    action or inaction by the Receiving Party or any affiliate,
                    agent, consultant or employee of the Receiving Party)
                    generally available to the public, or (ii) was in its
                    possession or known by it without restriction prior to
                    receipt from the Disclosing Party, or (iii) was rightfully
                    disclosed to it by a third party without restriction, or
                    (iv) was independently developed without use of any
                    Proprietary Information of the Disclosing Party. The
                    Receiving Party may make disclosures required by law or
                    court order provided the Receiving Party uses diligent
                    reasonable efforts to limit disclosure and to obtain
                    confidential treatment or a protective order and allows the
                    Disclosing Party to participate in the proceeding.
                  </p>
                  <p className="right-disclosure-text">
                    2. Immediately upon a request by the Disclosing Party at any
                    time, the Receiving Party will turn over to the Disclosing
                    Party all Proprietary Information of the Disclosing Party
                    and all documents or media containing any such Proprietary
                    Information and any and all copies or extracts thereof. The
                    Receiving Party understands that nothing herein (i) requires
                    the disclosure of any Proprietary Information of the
                    Disclosing Party or (ii) requires the Disclosing Party to
                    proceed with any transaction or relationship.
                  </p>
                  <p className="right-disclosure-text">
                    3. This Agreement applies only to disclosures made before
                    the first anniversary of this Agreement. The Receiving Party
                    acknowledges and agrees that due to the unique nature of the
                    Disclosing Party’s Proprietary Information, there can be no
                    adequate remedy at law for any breach of its obligations
                    hereunder, which breach may result in irreparable harm to
                    the Disclosing Party, and therefore, that upon any such
                    breach or any threat thereof, the Disclosing Party shall be
                    entitled to appropriate equitable relief, without the
                    requirement of posting a bond, in addition to whatever
                    remedies it might have at law. In the event that any of the
                    provisions of this Agreement shall be held by a court or
                    other tribunal of competent jurisdiction to be illegal,
                    invalid or unenforceable, such provisions shall be limited
                    or eliminated to the minimum extent necessary so that this
                    Agreement shall otherwise remain in full force and effect.
                    This Agreement shall be governed by the laws of the State of
                    California without regard to the conflicts of law provisions
                    thereof. This Agreement supersedes all prior discussions and
                    writings and constitutes the entire agreement between the
                    parties with respect to the subject matter hereof. The
                    prevailing party in any action to enforce this Agreement
                    shall be entitled to costs and attorneys’ fees. No waiver or
                    modification of this Agreement will be binding upon a party
                    unless made in writing and signed by a duly authorized
                    representative of such party and no failure or delay in
                    enforcing any right will be deemed a waiver.
                  </p>
                  <p
                    className="right-disclosure-text"
                    style={{ marginTop: "30px" }}
                  >
                    CloudBrands Inc. (d/b/a RestoGPT AI) <br />
                    Gary Chaglasyan <br />
                    CEO <br />
                    1027 Wilshire Blvd, Los Angeles, CA 90017
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default nonDisclosureModal;
