import React from "react";
import Tick from "../../../images/tick-green.png";
import Logo from "../../../images/thankyouLogo.svg";
import MobileLogo from "../../../images/mobile-logo.png";

const Index = () => {
  let url = localStorage.getItem("storefront_link")

  const handleBack = () => {
    window.open(url, "_blank");
  }

  return (
    <>
      <div className="thank-you-container">
        <div className="thank-you-content">
          <h3>Thanks!</h3>
          <div className="blue-part"></div>
          <div className="white-container">
            <p>
              Your Payment <br />
              Was Successful!
            </p>
            <img src={Tick} alt="tick" />
            <p className="text-black">
              In the next 24 hours, your AI storefront and dashboard will be
              completed and you will receive an email to join your private
              support chat and go live.
            </p>
            <button className="text-btn" onClick={handleBack}>Back</button>
          </div>
          <div className="logo-container">
            <img src={MobileLogo} alt="logo" />
            <p>THE FUTURE OF ONLINE ORDERING IS AI</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
