import HttpClient from "../../../../../utils/httpClient";
import axios from "axios";

const submitData = async (formData, siteName, decodedValue, convertedText) => {
  let decodeWord = decodedValue !== "" ? decodedValue : siteName;
  let url = `https://restogpt.ai/${decodeWord}`;
  let resNamefromUrl = convertedText !== "" ? convertedText : "";
  let resName = resNamefromUrl !== "" ?  resNamefromUrl : formData.restaurantName;
 
  let siteNameURl = '';
  if (siteName === 'laCanada' || siteName === 'lacanada') {
    siteNameURl = "LaCanada"
  } else {
    siteNameURl = "Normal"
  }
  let sheetName = "rfkxpe6o8o7sh";
  const loc = window.location.host;
  // sheetName =
  // loc.includes("velectico") || loc.includes("localhost")
  //   ? "ag3bmvtevjyoe"
  //   : sheetName;
  const address = formData.restaurantAddress1 + "," + formData.restaurantAddress2 +"," +formData.pin;
  const pos = formData.posState === "Other" ? formData.otherPosState : formData.posState;
  let data = {
    name: formData.name,
    email: formData.email,
    phone: formData.restaurantNumber,
    restaurant_name: resName,
    restaurant_address: address,
    website: formData.restaurantWebsite,
    delivery_type: formData.deliveryType,
    pos_type: pos,
    preparation_time: formData.preparationTime,
    data_from: url,
  };
  // console.log("data---",data);

  let sheetsData = new FormData();
  sheetsData.append("Customer_Name", formData.name);
  sheetsData.append("Customer_Email", formData.email);
  sheetsData.append("Restaurant_Name", resName);
  sheetsData.append("Phone_Number", formData.restaurantNumber);
  sheetsData.append("Restaurant_Address", address);
  sheetsData.append("Website", formData.restaurantWebsite);
  sheetsData.append("Delivery_Type", formData.deliveryType);
  sheetsData.append("POS_Type", pos);
  sheetsData.append("Site", siteNameURl);
  sheetsData.append("Preparation_Time", formData.preparationTime);
  sheetsData.append("Data_From", url);
  
  let result = await HttpClient.requestData("onboard", "POST", data);
  axios
  .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
  .then((response) => {});
};

export default submitData;
