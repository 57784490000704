import React from 'react';
import DeliveryPrefered from './deliveryPrefered';
import CompanyInfo from './companyInfo';
import Thanks from "../right/thanks";

const Index = () => {
    const [showForm, setShowForm] = React.useState("delivery");
    const [showText, setShowText] = React.useState(false);
  return (
    <>
     {showForm === "delivery" ? (
        <>
          <DeliveryPrefered setShowForm={setShowForm} />
        </>
      ) : showForm === "companyInfo" ? (
        <>
          <CompanyInfo setShowForm={setShowForm} setShowText={setShowText} />
        </>
      ) : showForm === "thanks" ? (
        <>
          <Thanks showText={showText}/>
        </>
      ) : null}
    </>
  )
}

export default Index