import HttpClient from "../../../../../utils/httpClient";
import axios from "axios";

const companySubmitData = async () => {
  let formDataDelivery = JSON.parse(localStorage.getItem("formDataDelivery"));
  let formDataCompany = JSON.parse(localStorage.getItem("formDataCompany"));
  let sheetName = "41xis92vpdzqe";

  let ownerName =
    formDataCompany.ownerFirstName + " " + formDataCompany.ownerLastName;

    const deliveryTime = formDataDelivery.deliveryTime === "Other" ? formDataDelivery.otherDeliveryTime + " min" : formDataDelivery.deliveryTime;
    const orderPreparationTime = formDataDelivery.orderPreparationTime === "Other" ? formDataDelivery.otherOrderPreparationTime + " min": formDataDelivery.orderPreparationTime;
    const deliveryCharges = formDataDelivery.deliveryCharges === "Other (indicate any custom split you’d like)" ? formDataDelivery.otherDeliveryCharges : formDataDelivery.deliveryCharges;
    const stripeFee = formDataDelivery.stripeFee === "Other (indicate any custom split you’d like)" ? formDataDelivery.otherStripeFee : formDataDelivery.stripeFee;
    const salesTax = formDataDelivery.salesTax === "Other" ? formDataDelivery.otherSalesTax + "%" : formDataDelivery.salesTax;

  let sheetsData = new FormData();
  sheetsData.append(
    "Order_Preparation_Time",
    orderPreparationTime
  );
  sheetsData.append("Delivery_Time", deliveryTime);
  sheetsData.append("Delivery_Charges", deliveryCharges);
  sheetsData.append("Stripe_Fee", stripeFee);
  sheetsData.append("Sales_Tax", salesTax);
  sheetsData.append("Storefront_Domain", formDataDelivery.storefrontDomain);

  sheetsData.append("Company_Name", formDataCompany.companyName);
  sheetsData.append("Company_Type", formDataCompany.companyType);
  sheetsData.append("EIN", formDataCompany.ein);
  sheetsData.append("DBA", formDataCompany.dba);
  sheetsData.append("Company_Address", formDataCompany.companyAddress);
  sheetsData.append("Phone", formDataCompany.phone);
  sheetsData.append("Owner_Name", ownerName);
  sheetsData.append("Owner_Phone", formDataCompany.ownerPhone);
  sheetsData.append("Owner_Email", formDataCompany.ownerEmail);
  sheetsData.append("Bank_Name", formDataCompany.bankName);
  sheetsData.append("Bank_Address", formDataCompany.bankAddress);

  axios
    .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
    .then((response) => {
      console.log("response", response);
      if (response) {
        localStorage.clear();
      }
    });
};

export default companySubmitData;
