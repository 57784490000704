import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightShort } from "react-bootstrap-icons";
import useUserForm from "../../hooks/onbording/user/new/useStateFile";
import useLocalStorage from "../../hooks/onbording/user/new/useLocalStorage";
import { preparationTime } from "../../selectOption";
import submitData from "../../hooks/onbording/user/new/submitData";

const Index = ({ setShowPage, siteName }) => {
  const nameRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const restaurantNumberRef = React.useRef(null);
  const restaurantNameRef = React.useRef(null);
  const restaurantAddressRef = React.useRef(null);
  const restaurantAddress2Ref = React.useRef(null);
  const pinRef = React.useRef(null);
  const restaurantWebsiteRef = React.useRef(null);
  const restaurantDeliveryRef = React.useRef(null);
  const restaurantPosRef = React.useRef(null);
  const restaurantPosOtherRef = React.useRef(null);

  const [changeComp, setChangeComp] = React.useState("name");
  const [
    formData,
    setFormData,
    formDataLa,
    setFormDataLa,
    errors,
    validateForm,
    validateFormForMobile,
    validateFormLa,
    validateFormForMobileLa,
  ] = useUserForm();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorage("formDataLa");

    let decodedValue = "";
  let convertedText = "";

  // Get the current URL
  const currentUrl = window.location.href;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("root")) {
    const encodedValue = currentUrl.split("=")[1];

    // Decode the base64-encoded value
    decodedValue = atob(decodeURIComponent(encodedValue));
    convertedText = decodedValue.split("-").join(" ");

    // console.log("decodedValue-----------", decodedValue);
  } else {
    decodedValue = "";
    // console.log("No 'root' parameter in the URL.");
  }

  let url = `https://restogpt.ai/${decodedValue}`;

  React.useEffect(() => {
    nameRef.current.focus();
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    // console.log("lastFilledComponent", lastFilledComponent);
    if (lastFilledComponent) {
      setFormDataLa({
        name: lastFilledComponent.name || "",
        email: lastFilledComponent.email || "",
        restaurantNumber: lastFilledComponent.restaurantNumber || "",
        restaurantName: lastFilledComponent.restaurantName || "",
        restaurantAddress1: lastFilledComponent.restaurantAddress1 || "",
        restaurantAddress2: lastFilledComponent.restaurantAddress2 || "",
        pin: lastFilledComponent.pin || "",
        restaurantWebsite: lastFilledComponent.restaurantWebsite || "",
        deliveryType: lastFilledComponent.deliveryType || "",
        posState: lastFilledComponent.posState || "",
        otherPosState: lastFilledComponent.otherPosState || "",
        preparationTime: lastFilledComponent.preparationTime || "",
      });

      let lastNonEmptyField = null;

      // Iterate through the keys in reverse order
      for (let key of Object.keys(lastFilledComponent).reverse()) {
        if (lastFilledComponent[key] !== "") {
          lastNonEmptyField = key;
          break;
        }
      }

      if (lastNonEmptyField) {
        setChangeComp(
          lastNonEmptyField === "otherPosState" ? "posState" : lastNonEmptyField
        );
      }
    }
  }, []);

  const handleInputChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormDataLa((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      // console.log("formDataLa---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    handleInputChangeWithStorageUpdate({
      target: { name, value: numericValue },
    });
  };

  React.useEffect(() => {
    if (changeComp === "email") {
      emailRef.current.focus();
    } else if (changeComp === "restaurantNumber") {
      restaurantNumberRef.current.focus();
    } else if (changeComp === "restaurantName") {
      restaurantNameRef.current.focus();
    } else if (
      changeComp === "restaurantAddress1" &&
      formDataLa.restaurantAddress1 === ""
    ) {
      restaurantAddressRef.current.focus();
    } else if (
      changeComp === "restaurantAddress2" &&
      formDataLa.restaurantAddress2 === ""
    ) {
      restaurantAddress2Ref.current.focus();
    } else if (changeComp === "pin" && formDataLa.pin === "") {
      pinRef.current.focus();
    } else if (changeComp === "restaurantWebsite") {
      restaurantWebsiteRef.current.focus();
    } 
    // else if (changeComp === "deliveryType") {
    //   restaurantDeliveryRef.current.focus();
    // } else if (changeComp === "posState") {
    //   if (formDataLa.posState === "Other") {
    //     restaurantPosOtherRef.current.focus();
    //   } else {
    //     restaurantPosRef.current.focus();
    //   }
    // }
  }, [changeComp, formDataLa]);

  const checkAndProceedToNext = async (current, next) => {
    let isAllowed = await validateFormForMobileLa(current);
    if (isAllowed) {
      setChangeComp(next);
      if (current === "preparationTime") {
        setShowPage(next);
        submitData(formDataLa, siteName, decodedValue, convertedText);
        localStorage.clear();
      }
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };

  const handleKeyPress = (e, current, next) => {
    if (e.key === "Enter") {
      e.preventDefault();
      checkAndProceedToNext(current, next);
    }
  };

  return (
    <>
      <div className="mobile-form-container">
        <Container>
          <Row>
            <Col>
              <form className="styled-form">
                {changeComp === "name" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="name">
                        1<ArrowRightShort />
                        Your full name<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        id="name"
                        name="name"
                        placeholder="e.g. Robert Cooperman"
                        value={formDataLa.name}
                        onChange={handleInputChangeWithStorageUpdate}
                        onKeyDown={(e) => {
                          handleKeyPress(e, "name", "email");
                        }}
                        required
                        ref={nameRef}
                      />
                      <span className="error">{errors.name}</span>
                    </div>
                    <div className="btn-box">
                      {/* <button className="back-btn"> &lt; back </button> */}
                      <div className="back-btn"></div>
                      <div
                        className="next-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          checkAndProceedToNext("name", "email");
                        }}
                      >
                        next &gt;
                      </div>
                    </div>
                  </>
                ) : changeComp === "email" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="email">
                        2<ArrowRightShort />
                        Your email<span>*</span>
                      </label>
                      <input
                        type="email"
                        className="input-form"
                        id="email"
                        name="email"
                        placeholder="stellas@gmail.com"
                        value={formDataLa.email}
                        onChange={handleInputChangeWithStorageUpdate}
                        onKeyDown={(e) => {
                          handleKeyPress(e, "email", "restaurantNumber");
                        }}
                        required
                        ref={emailRef}
                      />
                      <span className="error">{errors.email}</span>
                    </div>
                    <div className="btn-box">
                      <button
                        className="back-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeComp("name");
                        }}
                      >
                        {" "}
                        &lt; back{" "}
                      </button>
                      <button
                        className="next-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          checkAndProceedToNext("email", "restaurantNumber");
                        }}
                      >
                        {" "}
                        next &gt;{" "}
                      </button>
                    </div>
                  </>
                ) : changeComp === "restaurantNumber" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="restaurantNumber">
                        3<ArrowRightShort />
                        Your phone number<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        id="restaurantNumber"
                        name="restaurantNumber"
                        placeholder="e.g. 123456789"
                        value={formDataLa.restaurantNumber}
                        onChange={handleNumberInputChange}
                        onKeyDown={(e) => {
                          handleKeyPress(
                            e,
                            "restaurantNumber",
                            "restaurantName"
                          );
                        }}
                        required
                        ref={restaurantNumberRef}
                      />
                      <span className="error">{errors.restaurantNumber}</span>
                    </div>
                    <div className="btn-box">
                      <button
                        className="back-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeComp("email");
                        }}
                      >
                        {" "}
                        &lt; back{" "}
                      </button>
                      <button
                        className="next-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          checkAndProceedToNext(
                            "restaurantNumber",
                            "restaurantName"
                          );
                        }}
                      >
                        {" "}
                        next &gt;{" "}
                      </button>
                    </div>
                  </>
                ) : changeComp === "restaurantName" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="restaurantName">
                        4<ArrowRightShort />
                        Restaurant name<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        id="restaurantName"
                        name="restaurantName"
                        placeholder="e.g. Stella’s Pizza"
                        value={formDataLa.restaurantName}
                        onChange={handleInputChangeWithStorageUpdate}
                        onKeyDown={(e) => {
                          handleKeyPress(
                            e,
                            "restaurantName",
                            "restaurantAddress1"
                          );
                        }}
                        required
                        ref={restaurantNameRef}
                      />
                      <span className="error">{errors.restaurantName}</span>
                    </div>
                    <div className="btn-box">
                      <button
                        className="back-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeComp("restaurantNumber");
                        }}
                      >
                        {" "}
                        &lt; back{" "}
                      </button>
                      <button
                        className="next-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          checkAndProceedToNext(
                            "restaurantName",
                            "restaurantAddress1"
                          );
                        }}
                      >
                        {" "}
                        next &gt;{" "}
                      </button>
                    </div>
                  </>
                ) : changeComp === "restaurantAddress1" ||
                  changeComp === "restaurantAddress2" ||
                  changeComp === "pin" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="restaurant-address">
                        5<ArrowRightShort />
                        Restaurant address<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        id="restaurantAddress1"
                        name="restaurantAddress1"
                        placeholder="e.g. 1010 Wilshire Blvd"
                        value={formDataLa.restaurantAddress1}
                        onChange={handleInputChangeWithStorageUpdate}
                        onKeyDown={(e) => {
                          handleKeyPress(
                            e,
                            "restaurantAddress1",
                            "restaurantWebsite"
                          );
                        }}
                        required
                        ref={restaurantAddressRef}
                      />{" "}
                      <span className="error">{errors.restaurantAddress1}</span>
                      <br />
                      <input
                        type="text"
                        className="input-form"
                        id="restaurantAddress2"
                        name="restaurantAddress2"
                        placeholder="e.g. Los Angeles, CA"
                        value={formDataLa.restaurantAddress2}
                        onChange={handleInputChangeWithStorageUpdate}
                        onKeyDown={(e) => {
                          handleKeyPress(
                            e,
                            "restaurantAddress1",
                            "restaurantWebsite"
                          );
                        }}
                        required
                        ref={restaurantAddress2Ref}
                      />{" "}
                      <span className="error">{errors.restaurantAddress2}</span>
                      <br />
                      <input
                        type="text"
                        className="input-form"
                        id="pin"
                        name="pin"
                        placeholder="e.g. 90017"
                        value={formDataLa.pin}
                        onInput={handleNumberInputChange}
                        onKeyDown={(e) => {
                          handleKeyPress(
                            e,
                            "restaurantAddress1",
                            "restaurantWebsite"
                          );
                        }}
                        required
                        ref={pinRef}
                      />
                      <span className="error">{errors.pin}</span>
                    </div>
                    <div className="btn-box">
                      <button
                        className="back-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeComp("restaurantName");
                        }}
                      >
                        {" "}
                        &lt; back{" "}
                      </button>
                      <button
                        className="next-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          checkAndProceedToNext(
                            "restaurantAddress1",
                            "restaurantWebsite"
                          );
                        }}
                      >
                        {" "}
                        next &gt;{" "}
                      </button>
                    </div>
                  </>
                ) : changeComp === "restaurantWebsite" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="restaurantWebsite">
                        6<ArrowRightShort />
                        Restaurant website<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        id="restaurantWebsite"
                        name="restaurantWebsite"
                        placeholder="e.g. www.stellas.com"
                        value={formDataLa.restaurantWebsite}
                        onChange={handleInputChangeWithStorageUpdate}
                        onKeyDown={(e) => {
                          handleKeyPress(
                            e,
                            "restaurantWebsite",
                            "preparationTime"
                          );
                        }}
                        required
                        ref={restaurantWebsiteRef}
                      />
                      <span className="error">{errors.restaurantWebsite}</span>
                    </div>
                    <div className="btn-box">
                      <button
                        className="back-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeComp("restaurantAddress1");
                        }}
                      >
                        {" "}
                        &lt; back{" "}
                      </button>
                      <button
                        className="next-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          checkAndProceedToNext(
                            "restaurantWebsite",
                            "preparationTime"
                          );
                        }}
                      >
                        {" "}
                        next &gt;{" "}
                      </button>
                    </div>
                  </>
                ) : changeComp === "preparationTime" ? (
                  <>
                    <div>
                      <div className="form-group">
                        <label>
                          7 <ArrowRightShort />
                          Order preparation time
                          <span>*</span>
                        </label>
                        <select
                          className="input-form"
                          id="preparationTime"
                          name="preparationTime"
                          onChange={handleInputChangeWithStorageUpdate}
                          onKeyDown={(e) => {
                            handleKeyPress(e, "preparationTime", "thanksPage");
                          }}
                          value={formDataLa.preparationTime}
                          // ref={restaurantDeliveryRef}
                        >
                          <option value="">Please select</option>
                          {preparationTime.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                        <span className="error">{errors.preparationTime}</span>
                      </div>
                      <div className="btn-box">
                        <button
                          className="back-btn"
                          onClick={(e) => {
                            e.preventDefault(e);
                            setChangeComp("restaurantWebsite");
                          }}
                        >
                          {" "}
                          &lt; back{" "}
                        </button>
                        <button
                          className="next-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            checkAndProceedToNext(
                              "preparationTime",
                              "thanksPage"
                            );
                          }}
                        >
                          {" "}
                          next &gt;{" "}
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
