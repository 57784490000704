import React from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { PlayFill, Linkedin, Instagram, Tiktok } from "react-bootstrap-icons";
import { Typewriter } from "react-simple-typewriter";
// import Logo from "../images/logo.svg";
// import Logo from "../images/logonew.svg";
import Logo from "../images/logonew2.svg";
import Footer from "../footer/index";
// import LogoMobile from "./images/logo-mobile.svg";
//import footerLogo from "./images/footer-logo.png";
// import Phone from "../images/phone.png";
import Phone from "../images/phonenew.png";
import Banner from "../images/banner.webp";
import Banner2 from "../images/banner2.webp";
// import Banner3 from "../images/banner3.webp";
// import Banner3 from "../images/bannernew3.svg";
import Banner3 from "../images/banner3new.svg";
// import BannerMobile from "../images/banner-mobile.webp";
import bannerIcon1 from "../images/banner-icon-1.png";
import bannerIcon2 from "../images/banner-icon-2.png";
import bannerIcon3 from "../images/banner-icon-3.png";
import bannerIcon4 from "../images/banner-icon-4.png";
import FeaturesImage from "../images/features-img.png";
import FeaturesImageNew from "../images/fratures-image-new.png";
import FeaturesImageMobile from "../images/features-img-mobile.png";
import SuccessGenerated from "../images/success-generated.png";
import Slider1 from "../images/slider/slider1.png";
import Slider2 from "../images/slider/slider2.png";
import Slider3 from "../images/slider/slider3.png";
import Slider4 from "../images/slider/slider4.png";
import Slider5 from "../images/slider/slider5.png";
import Slider6 from "../images/slider/slider6.png";
import Slider7 from "../images/slider/slider7.png";
import Slider8 from "../images/slider/slider8.png";
import Slider9 from "../images/slider/slider9.png";
import Slider10 from "../images/slider/slider10.png";
import Slider11 from "../images/slider/slider11.png";
import BigSlider1 from "../images/slider/bigSlider/slider1.png";
import BigSlider2 from "../images/slider/bigSlider/slider2.png";
import BigSlider3 from "../images/slider/bigSlider/slider3.png";
import BigSlider4 from "../images/slider/bigSlider/slider4.png";
import BigSlider5 from "../images/slider/bigSlider/slider5.png";
import BigSlider6 from "../images/slider/bigSlider/slider6.png";
import BigSlider7 from "../images/slider/bigSlider/slider7.png";
import BigSlider8 from "../images/slider/bigSlider/slider8.png";
import BigSlider9 from "../images/slider/bigSlider/slider9.png";
import BigSlider10 from "../images/slider/bigSlider/slider10.png";
import BigSlider11 from "../images/slider/bigSlider/slider11.png";
import C1 from "../images/collaboration/_AWS.svg";
import C2 from "../images/collaboration/_Doordash.svg";
import C3 from "../images/collaboration/_IDWA.svg";
import C4 from "../images/collaboration/_Microsoft.svg";
import C5 from "../images/collaboration/_Nvidia.svg";
import C6 from "../images/collaboration/_OnDeck.svg";
import C7 from "../images/collaboration/_OrderwGoogle.svg";
import C8 from "../images/collaboration/_Stripe.svg";
import C9 from "../images/collaboration/_UberDirect.svg";
import C10 from "../images/collaboration/_PH_P_Day.svg";
import C12 from "../images/collaboration/_Product_hunt2.svg";
import C11 from "../images/collaboration/_PH_Artificial.svg";
import F1 from "../images/f1.svg";
import F2 from "../images/f2.svg";
import F3 from "../images/f3.svg";
// import P1 from "../images/p1.png";
// import P2 from "../images/p2.png";
// import P3 from "../images/p3.png";
// import P1Back from "../images/p1-back.png";
// import P2Back from "../images/p2-back.png";
// import P3Back from "../images/p3-back.png";
import P1 from "../images/p1.png";
import P2 from "../images/p2.png";
import P3 from "../images/p3.png";
import P4 from "../images/p4.png";
import P1Back from "../images/p1new-back.png";
import P2Back from "../images/p2new-back.png";
import P3Back from "../images/p3new-back.png";
import FooterIcon from "../images/footerIcon.png";
import FooterLogo from "../images/footer-logo.png";
// import FooterLogo from "../images/footer-logo.svg";

import WarningIcon from "../images/warning-icon.png";

import FormNew from "../component/FormNew";
import Loader from "../component/Loader";

import HttpClient from "../utils/httpClient";
import carveLine from "../images/carve-line.png";
//import $ from 'jquery';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modal from "react-bootstrap/Modal";
import StorfrontModal from "../popup/Storefront/StorefrontModal";
import ManagementModal from "../popup/Management/ManagementModal";
import CrmModal from "../popup/CRM/CrmModal";
import LastModal from "../popup/LastMile/LastMileModal";
import Price from "../component/price/index";
import Slider from "../component/slider/index";
import useWindowResize from "../onbording/hooks/onbording/static/useWindowResize";

const HomePage = () => {
  const options = {
    loop: true,
    items: 4,
    margin: 20,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 4,
      },
    },
  };
  const options2 = {
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    center: true,
    autoplay: true,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };
  const options3 = {
    loop: true,
    items: 7,
    margin: 20,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 4,
      },
    },
  };
  const options4 = {
    loop: true,
    items: 7,
    margin: 20,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 4,
      },
    },
  };
  const options5 = {
    loop: false,
    items: 1,
    margin: 0,
    mouseDrag: false,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav:false,
      },
      
      992: {
        items: 1,
      },
    },
  };

  // const hash = window.location.hash;
  // console.log("hash",hash);

  const workRef = React.useRef(null);
  const featuresRef = React.useRef(null);
  const benefitRef = React.useRef(null);
  const caseRef = React.useRef(null);
  const modelRef = React.useRef(null);
  const formRef = React.useRef(null);
  const [text, setText] = React.useState("");
  const textToType = "frequently";
  const typingSpeed = 100; // Adjust the typing speed (in milliseconds) as needed
  const eraseSpeed = 50; // Adjust the erasing speed (in milliseconds) as needed

  const { screenWidth, screenHeight } = useWindowResize();

  const [hashData, setHashData] = React.useState("");

  const [isScrolled, setIsScrolled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [checkUserRegion, setCheckUserRegion] = React.useState(false);
  const [displayYoutubeVideo, setDisplayYoutubeVideo] = React.useState(false);
  const [showCircles, setShowCircles] = React.useState(false);
  const [carousalPicture, setCarousalPicture] =
    React.useState(SuccessGenerated);

  React.useEffect(() => {
    isLoading();
    // setLoading(true);
    // getUserIP();
    // allData();
    // let hash = window.location.hash;
    // setHashData(hash)
  }, []);

  // React.useEffect(() => {
  //   const hash = window.location.hash;
  //   console.log('Hash:', hash);
  //   if (hash === '#FEATURES' && featuresRef.current) {
  //     // Scroll after the component has rendered
  //     featuresRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, []);

  React.useLayoutEffect(() => {
    const hash = window.location.hash;
    setTimeout(() => {
      if (hash === "#WORKS" && workRef.current) {
        // Scroll again if there was any chance that the layout has changed
        workRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#FEATURES" && featuresRef.current) {
        // Scroll again if there was any chance that the layout has changed
        featuresRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#BENEFITS" && benefitRef.current) {
        // Scroll again if there was any chance that the layout has changed
        benefitRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#CASESTUDIES" && caseRef.current) {
        // Scroll again if there was any chance that the layout has changed
        caseRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#PRICE" && modelRef.current) {
        // Scroll again if there was any chance that the layout has changed
        modelRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#GENERATE" && formRef.current) {
        // Scroll again if there was any chance that the layout has changed
        formRef.current.scrollIntoView({ behavior: "smooth" });
        handleFocus();
      }
    }, 2000);
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      if (screenWidth > 992) {
        if (window.scrollY > 200) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      } else {
        if (window.scrollY > 10) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  // const allData = async () => {
  //   let response = await fetch(
  //     "http://54.152.25.106/dispute/dispute/getall/12345"
  //   );
  //   const data = await response.json();
  //   console.log("all data------", data);
  // };

  const isLoading = (loading) => {
    // setLoading(loading);
    if (loading) {
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    setTimeout(() => {
      checkWebsiteStatus();
    }, 2000);
  };

  const checkWebsiteStatus = async () => {
    let result = await HttpClient.requestData("restogptstatus", "GET");
    if (result.status === false) {
      handleShowWarning();
    }
  };

  // const getUserIP = async () => {
  //   let result = await fetch("https://api.ipify.org?format=json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       return data.ip;
  //     });
  //   // console.log("user IP----",result);
  //   checkUserIP(result);
  // };

  // const checkUserIP = async (userIp) => {
  //   const options = { method: "GET" };
  //   let api_key = process.env.REACT_APP_IP_KEY;
  //   let result = await fetch(
  //     `https://ipgeolocation.abstractapi.com/v1?ip_address=${userIp}&api_key=${api_key}`,
  //     options
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       // console.log(response)
  //       return response;
  //     })
  //     .catch((err) => console.error(err));
  //   // console.log("user IP address response----", result);
  //   if (result.country_code === "IN" || result.country_code === "US") {
  //     // console.log("Get the response ");
  //     setCheckUserRegion(true);
  //     setLoading(false);
  //     // scrollToFunction()
  //   } else {
  //     setCheckUserRegion(false);
  //     setLoading(false);
  //   }
  // };

  // const scrollToFunction = () => {
  //   if (hashData != null && hashData == "#FEATURES") {
  //     // Scroll to the features section
  //     featuresRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleShowWarning = () => setShowWarning(true);

  const handleShowVideo = () => {
    setDisplayYoutubeVideo(true);
  };

  const handleFocus = () => {
    document.getElementById("restaurantMenu").focus();
    setShowCircles(true);
    // Blink the circles
    setTimeout(() => {
      setShowCircles(false);
      setTimeout(() => setShowCircles(true), 500);
    }, 500);
  };


  const handleScroll = (ref, id) => {
    let navbarHeightOffset = document.querySelector(".navbar").offsetHeight;
    let navbarHeight = navbarHeightOffset + 500;
    let element = document.getElementById(id);
    let scrollTop = element - navbarHeight;
    if (element) {
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="wrapper">
          <div className="content">
            <div className={`header ${isScrolled ? "headerSolid" : ""}`}>
              <Navbar collapseOnSelect expand="lg">
                <Container fluid className="justify-content-between">
                  <Navbar.Brand href="#home" className="logo">
                    <img src={Logo} />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-center center"
                  >
                    <Nav className="menu">
                      {/* <Nav.Link href="#WORKS" onClick={(e) => handleScroll(e, 'works-section')}>HOW IT WORKS</Nav.Link>
                      <Nav.Link href="#FEATURES" onClick={(e) => handleScroll(e, 'features-section')}>FEATURES</Nav.Link>
                      <Nav.Link href="#BENEFITS" onClick={(e) => handleScroll(e, 'benefits-section')}>BENEFITS</Nav.Link>
                      <Nav.Link href="#CASESTUDIES" onClick={(e) => handleScroll(e, 'casestudies-section')}>CASE STUDIES</Nav.Link>
                      <Nav.Link href="#MODEL" onClick={(e) => handleScroll(e, 'model-section')}>MODEL</Nav.Link> */}
                      <Nav.Link
                        href="#WORKS"
                        onClick={() => handleScroll(workRef, "WORKS")}
                      >
                        HOW IT WORKS
                      </Nav.Link>
                      <Nav.Link
                        href="#FEATURES"
                        onClick={() => handleScroll(featuresRef, "FEATURES")}
                      >
                        FEATURES
                      </Nav.Link>
                      <Nav.Link
                        href="#BENEFITS"
                        onClick={() => handleScroll(benefitRef, "BENEFITS")}
                      >
                        BENEFITS
                      </Nav.Link>
                      <Nav.Link
                        href="#CASESTUDIES"
                        onClick={() => handleScroll(caseRef, "CASESTUDIES")}
                      >
                        CASE STUDIES
                      </Nav.Link>
                      <Nav.Link
                        href="#PRICE"
                        onClick={() => handleScroll(modelRef, "PRICE")}
                      >
                        PRICE
                      </Nav.Link>
                      <Nav.Link
                        href="#GENERATE"
                        className="gen-btn"
                        onClick={handleFocus}
                      >
                        GENERATE
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
            <div className="header-section">
              {screenWidth > 992 ? (
                <p className="header-section-text">
                  <span className="header-section-span">AI</span> for{" "}
                  <span className="header-section-span">
                    restaurant order generation.{" "}
                  </span>
                  Turn every online order into <br />a{" "}
                  <span className="header-section-span">loyal customer.</span>
                </p>
              ) : (
                <p className="header-section-text">
                  <span className="header-section-span">AI</span> for{" "}
                  <span className="header-section-span">
                    restaurant order generation.{" "}
                  </span>
                  Turn every online order into a{" "}
                  <span className="header-section-span">loyal customer.</span>
                </p>
              )}
            </div>
            <div className="banner">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="text mobile">
                      <h1>
                        Add your menu link and start delivering in 24 hours
                      </h1>
                    </div>
                  </Col>
                  <Col
                    xxl="5"
                    xl={{ span: 5, order: "last" }}
                    lg={{ span: 5, order: "last" }}
                    id="Start"
                  >
                    <div id="form"></div>
                    <Carousel interval={3000}>
                      <Carousel.Item>
                        <div className="image">
                          <img src={Phone} className="banner-desktop" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="image2">
                          <img src={Banner2} />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="image2">
                          <img src={Banner3} />
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </Col>
                  <Col xl="7" xxl="7" lg="7" className="d-flex">
                    <div className="text" id="input-id">
                      {/* <h1 ref={formRef}>
                        Make your customers{" "}
                        <span className="input-text-head">order more</span>{" "}
                        <span>
                          <Typewriter
                            className="type-text"
                            loop
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={2000}
                            words={["[frequently]"]}
                          />
                        </span>
                      </h1> */}
                      <p className="input-text-para">
                        {/* AI starts here: add your menu <br /> link below and
                        start delivering <br /> in 24 hours{" "}
                        <span><sup>[and it's free]</sup></span> */}
                        Add your menu link to RestoGPT AI and start getting
                        orders in 24 hours
                      </p>
                      <FormNew
                        isLoading={isLoading}
                        showCircles={showCircles}
                      />
                      {/* {checkUserRegion ? (
                        <FormNew
                          isLoading={isLoading}
                          showCircles={showCircles}
                        />
                      ) : (
                        <>
                          <h3>
                            RestoGPT AI is not yet available in your region (US
                            only) 😢
                          </h3>
                          <h3>However, our AI will be with you soon! 🤖</h3>
                        </>
                      )} */}
                    </div>
                  </Col>
                </Row>
                {/* <div id="WORKS" ref={workRef}></div> */}
              </Container>
            </div>
          </div>
          <div className="banner-work" id="WORKS" ref={workRef}>
            <Container>
              <Row>
                <Col lg={{ span: 7, order: "last" }}>
                  <div className="about">
                    <div>
                      <h2>
                        <span>
                          What <span className="text-small">is</span>
                        </span>{" "}
                        RestoGPT AI?
                      </h2>
                      <p>
                        RestoGPT AI is a{" "}
                        <span>Restaurant Marketing and Sales Platform,</span>{" "}
                        built with our advanced online ordering and delivery
                        software, <span>converting</span> sales data into a
                        structured customer database and <span>activating</span>{" "}
                        marketing campaigns to engage and improve customer
                        retention [plus the order frequency].
                      </p>
                    </div>
                  </div>
                  <div className="image">
                    <img src={FeaturesImageMobile} />
                  </div>
                </Col>
                <Col lg="5">
                  <div className="text">
                    <img src={carveLine} className="line" />
                    <h3>
                      We’ve made it easy <br />
                      as <span>1-2-3</span>
                    </h3>
                    <div className="item">
                      <div className="icon">1</div>
                      <div className="right">
                        <h6>
                          Answer a few questions <br />
                          <span>[takes less than 2 minutes]</span>
                        </h6>
                      </div>
                    </div>
                    <div className="item two">
                      <div className="icon">2</div>
                      <div className="right">
                        <h6>
                          Get your AI-integrated storefront{" "}
                          <span>[with customer marketing automation]</span>
                        </h6>
                      </div>
                    </div>
                    <div className="item three">
                      <div className="icon">3</div>
                      <div className="right">
                        <h6>
                          Receive and deliver orders <br />
                          <span>[on autopilot]</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <div id="FEATURES" ref={featuresRef}></div> */}
            </Container>
          </div>
          <div
            className="description-container"
            id="FEATURES"
            ref={featuresRef}
          >
            <div className="features">
              <Container>
                <Row>
                  <Col lg="7">
                    <div className="text">
                      <h3>
                        What do you get <span className="text-small">with</span>{" "}
                        <span>RestoGPT AI?</span>
                      </h3>
                      {/* <p>
                        Think of RestoGPT as your <span>AI employee</span>, that
                        builds and manages your entire online ordering and
                        delivery business.<br></br> <br /> It all starts with a branded
                        website and customer-facing storefront, an AI dashboard
                        - generating orders from all channels, last-mile
                        delivery dispatch, menu maintenance, customer support,
                        CRM and most importantly, data-driven automation,
                        activating marketing interaction and{" "}
                        <span>customer engagement</span>.
                      </p> */}
                      <p className="para1">
                        Think of RestoGPT as your <span>AI employee</span> that
                        builds and manages your entire online ordering and
                        delivery business.
                      </p>
                      <p className="para2">
                        It all starts with a branded website and customer-facing
                        storefront, an AI dashboard managing orders from all
                        online channels, last-mile delivery dispatch, menu
                        maintenance, customer support, CRM and [MOST]
                        importantly, data-driven automation triggering marketing
                        campaigns to engage, interact and{" "}
                        <span>
                          increase customer retention and repeat orders
                        </span>
                        .
                      </p>
                      <p className="para3">
                        In simple terms: AI consolidates all orders, builds
                        customer database, triggers marketing campaigns,
                        generates more [direct] orders.
                      </p>
                    </div>
                  </Col>
                  <Col lg="5">
                    <div className="image">
                      <img src={FeaturesImageNew} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="sub-features">
              <Container className="for-mobile">
                <OwlCarousel className="success-slider owl-theme" {...options5}>
                  <div className="item">
                    <h3>
                      <span>
                        DATA MARKETING
                        <br />
                      </span>{" "}
                      AUTOMATION
                      <p>[DATA-DRIVEN ENGAGEMENT CAMPAIGNS]</p>
                    </h3>
                    <div className="image">
                      <img src={P3Back} className="back" />
                      <img src={P2} className="phone" />
                    </div>
                    <CrmModal />
                  </div>
                  <div className="item">
                    <h3>
                      <span>WEBSITE</span>{" "}
                      <span className="text-small">
                        AND
                        <br />
                      </span>{" "}
                      STOREFRONT
                      <p>[CATERING + ORDERING SOLUTIONS]</p>
                    </h3>
                    <div className="image">
                      <img src={P1Back} className="back" />
                      <img src={P1} className="phone" />
                    </div>
                    <StorfrontModal />
                  </div>
                  <div className="item">
                    <h3>
                      <span>
                        AI ORDER
                        <br />
                      </span>{" "}
                      MANAGEMENT
                      <p>[ADVANCED MENU MANAGEMENT SOLUTIONS]</p>
                    </h3>
                    <div className="image">
                      <img src={P2Back} className="back" />
                      <img src={P3} className="phone" />
                    </div>
                    <ManagementModal />
                  </div>
                  <div className="item">
                    <h3>
                      <span>
                        LAST MILE 
                      </span>{" "}
                      DELIVERY MANAGEMENT
                      <p>[ADVANCED DELIVERY SOLUTIONS]</p>
                    </h3>
                    <div className="image">
                      <img src={P3Back} className="back" />
                      <img src={P4} className="phone" />
                    </div>
                    <LastModal />
                  </div>
                </OwlCarousel>
              </Container>
              <Container className="for-desktop">
                <Row>
                  <Col lg="6">
                    <div className="item">
                      <h3>
                        <span>
                          DATA MARKETING
                          <br />
                        </span>{" "}
                        AUTOMATION
                        <p>[DATA-DRIVEN ENGAGEMENT CAMPAIGNS]</p>
                      </h3>
                      <div className="image">
                        <img src={P3Back} className="back" />
                        <img src={P2} className="phone" />
                      </div>
                      <CrmModal />
                    </div>
                    <div
                      id="BENEFITS"
                      ref={benefitRef}
                      className="beni-scroll"
                    ></div>
                  </Col>
                  <Col lg="6">
                    <div className="item">
                      <h3>
                        <span>WEBSITE</span>{" "}
                        <span className="text-small">
                          AND
                          <br />
                        </span>{" "}
                        STOREFRONT
                        <p>[CATERING + ORDERING SOLUTIONS]</p>
                      </h3>
                      <div className="image">
                        <img src={P1Back} className="back" />
                        <img src={P1} className="phone" />
                      </div>
                      <StorfrontModal />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="item">
                      <h3>
                        <span>
                          AI ORDER
                          <br />
                        </span>{" "}
                        MANAGEMENT
                        <p>[ADVANCED MENU MANAGEMENT SOLUTIONS]</p>
                      </h3>
                      <div className="image">
                        <img src={P2Back} className="back" />
                        <img src={P3} className="phone" />
                      </div>
                      <ManagementModal />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="item">
                      <h3>
                        <span>
                          LAST MILE <br />
                        </span>{" "}
                        DELIVERY MANAGEMENT
                        <p>[ADVANCED DELIVERY SOLUTIONS]</p>
                      </h3>
                      <div className="image">
                        <img src={P3Back} className="back" />
                        <img src={P4} className="phone" />
                      </div>
                      <LastModal />
                    </div>
                    <div
                      id="BENEFITS"
                      ref={benefitRef}
                      className="beni-scroll"
                    ></div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="choose-sec"
              // id="BENEFITS" ref={benefitRef}
            >
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="heading">
                      <h2>
                        Why choose <span>RestoGPT ?</span>
                      </h2>
                      {screenWidth > 992 ? (
                        <p>
                          The{" "}
                          <span className="heading-para">
                            [future] of online ordering
                          </span>{" "}
                          and{" "}
                          <span className="heading-para">
                            customer engagement
                          </span>{" "}
                          is{" "}
                          <span style={{ color: "#ff00bf" }}>
                            RestoGPT AI.{" "}
                          </span>
                          Don’t miss out.
                        </p>
                      ) : (
                        <p>
                          The{" "}
                          <span className="heading-para">
                            [future] of online ordering
                          </span>{" "}
                          and{" "}
                          <span className="heading-para">
                            customer engagement
                          </span>{" "}
                          is <br />
                          <span style={{ color: "#ff00bf" }}>
                            RestoGPT AI.{" "}
                          </span>
                          Don’t miss out.
                        </p>
                      )}
                    </div>
                    <div className="choose-slider">
                      <OwlCarousel className="owl-theme" {...options2}>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 1</span> */}
                            <h5>
                              {" "}
                              <span
                                style={{ color: "#5A00CA", fontWeight: 700 }}
                              >
                                AI
                              </span>{" "}
                              Website and Storefronts
                            </h5>
                            <h6>[dynamic, branded - built to sell]</h6>
                            <p>
                              start accepting and delivering direct
                              orders in 24 hours
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 2</span> */}
                            <h5>
                              {" "}
                              <span
                                style={{ color: "#9C00FF", fontWeight: 700 }}
                              >
                                Increase
                              </span>{" "}
                              Direct Orders
                            </h5>
                            <h6>[Website, Google, Yelp, Social Media]</h6>
                            {/* <p>
                              don't pay commissions and keep your customer data
                              for loyalty
                            </p> */}
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 3</span> */}
                            <h5>
                              More{" "}
                              <span style={{ color: "#C09", fontWeight: 700 }}>
                                Tips
                              </span>{" "}
                            </h5>
                            <h6>[even on pickup orders]</h6>
                            <p>keep your staff happy and loyal</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 4</span> */}
                            <h5>
                              <span
                                style={{ color: "#19BF88", fontWeight: 700 }}
                              >
                                Deliver
                              </span>{" "}
                              More Orders
                            </h5>
                            <h6>[even at rush hour]</h6>
                            <p>no extra costs, overhead or drivers </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 5</span> */}
                            <h5>
                              Orders on{" "}
                              <span style={{ color: "#C09", fontWeight: 700 }}>
                                Autopilot
                              </span>
                            </h5>
                            <h6>[less staff]</h6>
                            <p>no errors or delays</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 6</span> */}
                            <h5>
                              <span
                                style={{ color: "#9C00FF", fontWeight: 700 }}
                              >
                                Save
                              </span>{" "}
                              on Card Processing Fees
                            </h5>
                            <h6>[flex options]</h6>
                            <p>extra 3% to the bottom line</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 7</span> */}
                            <h5>
                              No Missed{" "}
                              <span
                                style={{ color: "#19BF88", fontWeight: 700 }}
                              >
                                Calls
                              </span>
                            </h5>
                            <h6>[even during peak hours]</h6>
                            <p>convert missed calls into revenue</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 8</span> */}
                            <h5>
                              <span style={{ color: "#C09", fontWeight: 700 }}>
                                AI Voice
                              </span>{" "}
                              and Text Ordering
                            </h5>
                            <h6>[add voice and chat to website ordering]</h6>
                            <p>maximize order generation channels</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 9</span> */}
                            <h5>
                              <span
                                style={{ color: "#7B00DC", fontWeight: 700 }}
                              >
                                Lower
                              </span>{" "}
                              Your Payroll
                            </h5>
                            <h6>[efficiency with AI and automation]</h6>
                            <p>Hire your 24/7 AI employee</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 10</span> */}
                            <h5>
                              <span
                                style={{ color: "#19BF88", fontWeight: 700 }}
                              >
                                Reduce
                              </span>{" "}
                              3rd-Party Fees by Half
                            </h5>
                            <h6>[DoorDash / UberEats / GrubHub+]</h6>
                            <p>
                              use self-delivery AI dispatch - save 50% in fees
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 11</span> */}
                            <h5>
                              <span style={{ color: "#C09", fontWeight: 700 }}>
                                Smart Customer <br />
                              </span>{" "}
                              Data Platform
                            </h5>
                            <h6>[for promotions and customer engagement]</h6>
                            <p>
                              activate your customer data for order generation
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 11</span> */}
                            <h5>
                              Marketing and{" "}
                              <span
                                style={{ color: "#7B00DC", fontWeight: 700 }}
                              >
                                Loyalty
                              </span>
                            </h5>
                            <h6>
                              [campaigns to build customer retention on
                              autopilot]
                            </h6>
                            <p>
                              turn order #8745 into a loyal, returning customer
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="text">
                            {/* <span className="tag">Benefit 11</span> */}
                            <h5>
                              Generate{" "}
                              <span
                                style={{ color: "#7B00DC", fontWeight: 700 }}
                              >
                                More Orders
                              </span>
                            </h5>
                            <h6>[SMS and email campaigns]</h6>
                            <p>
                              automated, data-driven, targeted customer
                              communication
                            </p>
                          </div>
                        </div>
                      </OwlCarousel>
                      <div
                        id="CASESTUDIES"
                        ref={caseRef}
                        className="case-scroll"
                      ></div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="success-generated"
              // id="CASESTUDIES" ref={caseRef}
            >
              <Container>
                <Row>
                  <Col lg="6">
                    <div className="image">
                      <img src={BigSlider1} />
                    </div>
                  </Col>
                  <Col lg="6" className="d-flex align-items-center">
                    <h2 className="text">
                      <span style={{ fontWeight: "600" }}>20,000+</span>
                      <br />
                      restaurant storefronts
                      <br />
                      generated by AI
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <OwlCarousel
                      className="success-slider owl-theme"
                      {...options}
                    >
                      <div
                        class="item"
                        onClick={() => setCarousalPicture(BigSlider1)}
                      >
                        <div className="image">
                          <img src={Slider1} />
                        </div>
                        <a
                          href="https://restogpt.ai/elpatron"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      <div
                        class="item"
                        onClick={() => setCarousalPicture(BigSlider2)}
                      >
                        <div className="image">
                          <img src={Slider2} />
                        </div>
                        <a
                          href="https://restogpt.ai/boardwalk"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider3)}
                        >
                          <img src={Slider3} />
                        </div>
                        <a
                          href="https://restogpt.ai/dinos"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      {/* <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider4)}
                        >
                          <img src={Slider4} />
                        </div>
                        <a
                          href="https://restogpt.ai/triopizza"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div> */}
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider5)}
                        >
                          <img src={Slider5} />
                        </div>
                        <a
                          href="https://order.planchatacos.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider6)}
                        >
                          <img src={Slider6} />
                        </div>
                        <a
                          href="https://restogpt.ai/trejostacos"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      {/* <div class='item'>
                          <div className="image" onClick={() => setCarousalPicture(BigSlider7)}>
                            <img src={Slider7}/>
                          </div>
                          <a href="https://restogpt.ai/mamma_ramonas" target="_blank" rel="noreferrer" className="btun">VIEW STOREFRONT</a>
                      </div> */}
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider8)}
                        >
                          <img src={Slider8} />
                        </div>
                        <a
                          href="https://restogpt.ai/bigzeus"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider9)}
                        >
                          <img src={Slider9} />
                        </div>
                        <a
                          href="https://restogpt.ai/cpk/"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider10)}
                        >
                          <img src={Slider10} />
                        </div>
                        <a
                          href="https://restogpt.ai/farmersboys"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                      <div class="item">
                        <div
                          className="image"
                          onClick={() => setCarousalPicture(BigSlider11)}
                        >
                          <img src={Slider11} />
                        </div>
                        <a
                          href="https://restogpt.ai/pacificpizza"
                          target="_blank"
                          rel="noreferrer"
                          className="btun"
                        >
                          VIEW STOREFRONT
                        </a>
                      </div>
                    </OwlCarousel>
                  </Col>
                </Row>
              </Container>
            </div>
            <div id="PRICE" ref={modelRef} className="modal-scroll"></div>
          </div>
          <div className="model-sec">
            <Container>
              <Row>
                <Col lg="12">
                  <h3 className="heading">WE GENERATE ORDERS</h3>
                  <p className="para">
                    You choose the quantity and price per order. Don't overpay.
                  </p>
                </Col>

                {/* <Price /> */}
                <Slider handleFocus={handleFocus}/>
               
                {/* <Col lg="12" className="gen-btn-container">
                  <a href="#" className="gen-btun" onClick={handleFocus}>
                    GENERATE STOREFRONT NOW
                  </a>
                </Col> */}
              </Row>
              <Row>
                <Col lg="12">
                  <h3 className="heading-collaboration">
                    In collaboration with
                  </h3>
                </Col>
                <Row className="colla">
                  <Col lg="12">
                    <OwlCarousel className="owl-theme" {...options3}>
                      <div className="collaboration-image">
                        <img src={C5} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C1} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C8} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C2} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C9} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C7} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C4} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C3} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C6} />
                      </div>
                      <div className="collaboration-image">
                        <img src={C12} />
                      </div>
                      {/* <div className="collaboration-image">
                          <img src={C11} />
                        </div> */}
                      <div className="collaboration-image">
                        <img src={C10} />
                      </div>
                    </OwlCarousel>
                  </Col>
                </Row>
                {/* {screenWidth > 992 ? (
                  <Row>
                    <Col lg="12">
                      <OwlCarousel className="owl-theme" {...options3}>
                        <div className="collaboration-image">
                          <img src={C5} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C1} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C8} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C2} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C9} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C7} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C4} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C3} />
                        </div>
                        <div className="collaboration-image">
                          <img src={C6} />
                        </div>
                        
                        <div className="collaboration-image">
                          <img src={C10} />
                        </div>
                      </OwlCarousel>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C5} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C1} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C8} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C4} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C2} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C9} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C3} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="6" xs="6" className="p-0">
                      <div className="collaboration-image">
                        <img src={C6} style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <Col sm="12" xs="12" className="p-0">
                      <div className="collaboration-image">
                        <img src={C7} style={{ width: "50%" }} />
                      </div>
                    </Col>
                    <Col sm="12" xs="12" className="p-0">
                      <div className="collaboration-image">
                        <img src={C11} style={{ width: "50%" }} />
                      </div>
                    </Col>
                    <Col sm="12" xs="12" className="p-0">
                      <div className="collaboration-image">
                        <img src={C10} style={{ width: "50%" }} />
                      </div>
                    </Col>
                  </Row>
                )} */}
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      )}

      <Modal
        show={showWarning}
        onHide={handleCloseWarning}
        centered
        size="lg"
        className="AI-modal"
        style={{ textAlign: "center" }}
        backdrop="static"
      >
        <Modal.Body>
          {/* <button onClick={handleCloseWarning} className="closeBtn">
            <img src={CloseIcon} />
          </button> */}
          <img src={WarningIcon} />
          <p>
            Due to high demand, we are upgrading our system to process the
            increased number of requests.
          </p>

          <h2>Please try again later.</h2>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HomePage;
