import React from "react";
import Logo from "../../../images/onbordinglogo.png";
import StepPage from "./Top";
import { Container, Row, Col } from "react-bootstrap";
const Thanks = ({showText}) => {
  return (
    <>
    {showText ? null : (
      <StepPage/>
    )}
      <div className="right-container">
        <Container>
        <div className="right-wrapper">
          <div className="thanks-container">
            <h3 style={{marginBottom: 0}}>Thanks!</h3>
            <h3>Your response was submitted.</h3>
            {showText ? "" : (
               <p>
               In the next 24 hours, your AI storefront will <br /> be completed and you
               will receive an email <br /> to join your private chat group and go live.
             </p>
            )}
            <a href="https://getorderbyte.com/" className="logo">
              <img src={Logo} />
            </a>
            <p className="thanks-p"><span>The Future </span>of Online Ordering is AI</p>
          </div>
        </div>
        </Container>
      </div>
    </>
  );
};

export default Thanks;
