import React from "react";
import InputHints from "react-input-hints";
import validator from "validator";
import { ArrowRightShort } from "react-bootstrap-icons";

const Email = ({
  email,
  setEmail,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
}) => {
  React.useEffect(() => {
    var emailFocus = document.getElementById("emailId");
    emailFocus.focus();
  }, []);

  return (
    <>
      <div className="form-box">
        <p>
          9<ArrowRightShort />
          {/* 8<ArrowRightShort /> */}
          Enter your email address{""}
          <span>*</span>
        </p>
        <p className="comment-le">
          * This is the email where you will be sent your AI-generated
          storefront link{" "}
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <InputHints
            type="text"
            id="emailId"
            placeholders={["name@example.com"]}
            className="form-control"
            value={email}
            onChange={(val) => {
              setEmail(val.target.value);
              setErrorMessage("");
            }}
            onKeyPress={(e) => handleKeypress(e, current, next)}
          />
        </form>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
      </div>
    </>
  );
};

export default Email;
