const useLocalStorageDelivery = (key) => {
    const saveToLocalStorage = (data) => {
      localStorage.setItem(key, JSON.stringify(data));
    };
  
    const getFromLocalStorage = () => {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    };
  
    return { saveToLocalStorage, getFromLocalStorage };
  };
  
  export default useLocalStorageDelivery;