import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import useDeliveryForm from "../../hooks/onbording/user/new/useDeliveryForm";
import useLocalStorageDelivery from "../../hooks/onbording/user/new/useLocalStorageDelivery";

const MobileDelivery = ({ setComponent }) => {
  const [formData, setFormData, errors, validateForm] = useDeliveryForm();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorageDelivery("formDataDelivery");

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    if (lastFilledComponent) {
      setFormData({
        orderPreparationTime: lastFilledComponent.orderPreparationTime || "",
        deliveryTime: lastFilledComponent.deliveryTime || "",
        deliveryCharges: lastFilledComponent.deliveryCharges || "",
        stripeFee: lastFilledComponent.stripeFee || "",
        salesTax: lastFilledComponent.salesTax || "",
        storefrontDomain: lastFilledComponent.storefrontDomain || "",
      });
    }
  }, [setFormData]);

  const handleChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const onNext = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setComponent("companyForm");
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };
  return (
    <>
      <div className="mobile-form-container">
        <Container>
          <Row>
            <Col>
              <div className="head-title">
                <h3>Delivery Preferences</h3>
              </div>
              <form class="styled-form">
                <div class="form-group mobile-form">
                  <label htmlFor="name" className="form-label">
                    Order preparation time for pickup and delivery{" "}
                    <span>*</span>
                  </label>
                  <div className="radio-btn-group">
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="orderPreparationTime"
                        value="15 min"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.orderPreparationTime === "15 min"}
                      />
                      <span className="radio-text">15 min</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="orderPreparationTime"
                        value="20 min"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.orderPreparationTime === "20 min"}
                      />
                      <span className="radio-text">20 min</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="orderPreparationTime"
                        value="25 min"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.orderPreparationTime === "25 min"}
                      />
                      <span className="radio-text">25 min</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="orderPreparationTime"
                        value="Other"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.orderPreparationTime === "Other"}
                      />
                      <span className="radio-text">Other</span>
                    </div>
                  </div>
                  <span className="error">{errors.orderPreparationTime}</span>
                </div>
                <div class="form-group mobile-form">
                  <label htmlFor="name" className="form-label">
                    Delivery time <span>*</span>
                  </label>
                  <div className="radio-btn-group">
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryTime"
                        value="15 min"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.deliveryTime === "15 min"}
                      />
                      <span className="radio-text">15 min</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryTime"
                        value="20 min"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.deliveryTime === "20 min"}
                      />
                      <span className="radio-text">20 min</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryTime"
                        value="25 min"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.deliveryTime === "25 min"}
                      />
                      <span className="radio-text">25 min</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryTime"
                        value="Other"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.deliveryTime === "Other"}
                      />
                      <span className="radio-text">Other</span>
                    </div>
                  </div>
                  <span className="error">{errors.deliveryTime}</span>
                </div>
                <div class="form-group mobile-form">
                  <label htmlFor="name" className="form-label">
                    Delivery charges <span>*</span>
                  </label>
                  <div className="radio-btn-group">
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryCharges"
                        value="50/50 between restaurant and customer"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={
                          formData.deliveryCharges ===
                          "50/50 between restaurant and customer"
                        }
                      />
                      <span className="radio-text">
                        50/50 between restaurant and customer
                      </span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryCharges"
                        value="Paid by the restaurant"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={
                          formData.deliveryCharges === "Paid by the restaurant"
                        }
                      />
                      <span className="radio-text">Paid by the restaurant</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryCharges"
                        value="Paid by the customer"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={
                          formData.deliveryCharges === "Paid by the customer"
                        }
                      />
                      <span className="radio-text">Paid by the customer</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="deliveryCharges"
                        value="Other (indicate any custom split you’d like)"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={
                          formData.deliveryCharges ===
                          "Other (indicate any custom split you’d like)"
                        }
                      />
                      <span className="radio-text">
                        Other (indicate any custom split you’d like)
                      </span>
                    </div>
                  </div>
                  <span className="error">{errors.deliveryCharges}</span>
                </div>
                <div class="form-group mobile-form">
                  <label htmlFor="name" className="form-label">
                    Who should incur Stripe's credit card processing fee?{" "}
                    <span>*</span>
                  </label>
                  <div className="radio-btn-group">
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="stripeFee"
                        value="Paid by the restaurant"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={
                          formData.stripeFee === "Paid by the restaurant"
                        }
                      />
                      <span className="radio-text">Paid by the restaurant</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="stripeFee"
                        value="Paid by the customer"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.stripeFee === "Paid by the customer"}
                      />
                      <span className="radio-text">Paid by the customer</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="stripeFee"
                        value="Built into the price"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.stripeFee === "Built into the price"}
                      />
                      <span className="radio-text">Built into the price</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="stripeFee"
                        value="Other (indicate any custom split you’d like)"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={
                          formData.stripeFee ===
                          "Other (indicate any custom split you’d like)"
                        }
                      />
                      <span className="radio-text">
                        Other (indicate any custom split you’d like)
                      </span>
                    </div>
                  </div>
                  <span className="error">{errors.stripeFee}</span>
                </div>
                <div class="form-group mobile-form">
                  <label htmlFor="name" className="form-label">
                    Sales tax <span>*</span>
                  </label>
                  <div className="radio-btn-group">
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="salesTax"
                        value="8.5%"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.salesTax === "8.5%"}
                      />
                      <span className="radio-text">8.5%</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="salesTax"
                        value="9%"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.salesTax === "9%"}
                      />
                      <span className="radio-text">9%</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="salesTax"
                        value="9.5%"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.salesTax === "9.5%"}
                      />
                      <span className="radio-text">9.5%</span>
                    </div>
                    <div className="radio-btn">
                      <input
                        type="radio"
                        name="salesTax"
                        value="Other"
                        className="radio-input"
                        onChange={handleChangeWithStorageUpdate}
                        checked={formData.salesTax === "Other"}
                      />
                      <span className="radio-text">Other</span>
                    </div>
                  </div>
                  <span className="error">{errors.salesTax}</span>
                </div>
                <div class="form-group mobile-form">
                  <label htmlFor="name" className="form-label">
                    What domain/subdomain would you like to use for your
                    restaurant's storefront? <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    id="storefrontDomain"
                    name="storefrontDomain"
                    placeholder="e.g. order.yourdomain.com"
                    value={formData.storefrontDomain}
                    onChange={handleChangeWithStorageUpdate}
                  />
                  <span className="error">{errors.storefrontDomain}</span>
                </div>
                <div className="btn-form-container">
                  <button className="form-btn" onClick={onNext}>
                    Next
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MobileDelivery;
