import React from "react";
import { carousal } from "../../hooks/onbording/static/carousal";
import RestoCarousel from "../common/RestoCarousel";
import Carousel from "react-bootstrap/Carousel";
import MailIcon from "../../../images/mail-icon.png";
import { Container, Row, Col } from "react-bootstrap";

const Index = () => {
  return (
    <>
      <div className="left-container">
        <Container>
          <Row>
            <Col>
              <div className="mobile-headerr">
                <a href="mailto:hello@restogpt-ai.com">
                  <img src={MailIcon} />
                </a>
              </div>
              <div className="left-content">
                <h3 className="onbheader">
                  Why <span>RestoGPT AI?</span>
                </h3>
                <RestoCarousel />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
