import React from "react";
import About from "./about";
import FormDesktop from "./formDesktop";
import FormDesktoptow from "./formDesktoptow";
import FormDeskLa from "./formDeskLa";
import Thanks from "./thanks";

const Index = ({ siteName }) => {
  const [showForm, setShowForm] = React.useState("form");

  return (
    <>
      {showForm === "form" && siteName === "welcome" ? (
        <>
          <FormDesktop setShowForm={setShowForm} siteName={siteName}/>
        </>
      )  : showForm === "formtwo" && siteName === "welcome" ? (
        <>
          <FormDesktoptow setShowForm={setShowForm} siteName={siteName}/>
        </>
      ) : showForm === "form" && siteName === "lacanada" ? (
        <>
          <FormDeskLa setShowForm={setShowForm} siteName={siteName}/>
        </>
      ) : showForm === "thanks" ? (
        <>
          <Thanks />
        </>
      ) : null}
    </>
  );
};

export default Index;
