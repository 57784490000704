import { useState } from "react";

const useUserForm = () => {
  // let siteName = localStorage.getItem("siteName");
  // console.log("siteName form ",siteName);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    restaurantNumber: "",
    restaurantName: "",
    restaurantAddress1: "",
    restaurantAddress2: "",
    pin: "",
    restaurantWebsite: "",
    deliveryType: "",
    posState: "",
    otherPosState: "",
    preparationTime: "",
  });

  const [formDataLa, setFormDataLa] = useState({
    name: "",
    email: "",
    restaurantNumber: "",
    restaurantName: "",
    restaurantAddress1: "",
    restaurantAddress2: "",
    pin: "",
    restaurantWebsite: "",
    deliveryType: "",
    posState: "",
    otherPosState: "",
    preparationTime: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    restaurantNumber: "",
    restaurantName: "",
    restaurantAddress1: "",
    restaurantAddress2: "",
    pin: "",
    restaurantWebsite: "",
    deliveryTypeState: "",
    posState: "",
    otherPosState: "",
    preparationTime: "",
  });

  const validateForm = (siteName) => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.name) {
      valid = false;
      newErrors.name = "Name is required";
    } else {
      newErrors.name = "";
    }

    if (!formData.email) {
      valid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      valid = false;
      newErrors.email = "Invalid email format";
    } else {
      newErrors.email = "";
    }

    if (!formData.restaurantNumber) {
      valid = false;
      newErrors.restaurantNumber = "Phone number is required";
    } else {
      newErrors.restaurantNumber = "";
    }

    if (!formData.restaurantAddress1) {
      valid = false;
      newErrors.restaurantAddress1 = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress1 = "";
    }

    if (!formData.restaurantAddress2) {
      valid = false;
      newErrors.restaurantAddress2 = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress2 = "";
    }

    if (!formData.pin) {
      valid = false;
      newErrors.pin = "Zip code is required";
    }
    // else if (formData.pin.length < 6) {
    //   valid = false;
    //   newErrors.pin = "Pin must be at least 6 characters long";
    // }
    else if (!/^\d+$/.test(formData.pin)) {
      valid = false;
      newErrors.pin = "Zip code must contain only numbers";
    } else {
      newErrors.pin = "";
    }

    if (!formData.restaurantWebsite) {
      valid = false;
      newErrors.restaurantWebsite = "Restaurant website is required";
    } else {
      newErrors.restaurantWebsite = "";
    }

    if (!formData.deliveryType) {
      valid = false;
      newErrors.deliveryTypeState = "Select delivery type";
    } else {
      newErrors.deliveryTypeState = "";
    }

    if (!formData.posState) {
      valid = false;
      newErrors.posState = "Select POS system";
    } else {
      newErrors.posState = "";
    }

    if (formData.posState === "Other") {
      if (!formData.otherPosState) {
        valid = false;
        newErrors.otherPosState = "POS system is required";
      } else {
        newErrors.otherPosState = "";
      }
    } else {
      // Reset the error for otherPosState if posState is not "Other"
      newErrors.otherPosState = "";
    }

    setErrors(newErrors);
    // console.log("Is form valid?", newErrors);
    return valid;
  };

  const validateFormLa = (siteName) => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formDataLa.name) {
      valid = false;
      newErrors.name = "Name is required";
    } else {
      newErrors.name = "";
    }

    if (!formDataLa.email) {
      valid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formDataLa.email)) {
      valid = false;
      newErrors.email = "Invalid email format";
    } else {
      newErrors.email = "";
    }

    if (!formDataLa.restaurantNumber) {
      valid = false;
      newErrors.restaurantNumber = "Phone number is required";
    } else {
      newErrors.restaurantNumber = "";
    }

    if (!formDataLa.restaurantName) {
      valid = false;
      newErrors.restaurantName = "Restaurant name is required";
    } else {
      newErrors.restaurantName = "";
    }

    if (!formDataLa.restaurantAddress1) {
      valid = false;
      newErrors.restaurantAddress1 = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress1 = "";
    }

    if (!formDataLa.restaurantAddress2) {
      valid = false;
      newErrors.restaurantAddress2 = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress2 = "";
    }

    if (!formDataLa.pin) {
      valid = false;
      newErrors.pin = "Zip code is required";
    }
    // else if (formData.pin.length < 6) {
    //   valid = false;
    //   newErrors.pin = "Pin must be at least 6 characters long";
    // }
    else if (!/^\d+$/.test(formDataLa.pin)) {
      valid = false;
      newErrors.pin = "Zip code must contain only numbers";
    } else {
      newErrors.pin = "";
    }

    if (!formDataLa.restaurantWebsite) {
      valid = false;
      newErrors.restaurantWebsite = "Restaurant website is required";
    } else {
      newErrors.restaurantWebsite = "";
    }

    if (!formDataLa.preparationTime) {
      valid = false;
      newErrors.preparationTime = "Order preparation time is required";
    } else {
      newErrors.preparationTime = "";
    }
    setErrors(newErrors);
    // console.log("Is form valid?", newErrors);
    return valid;
  };

  const validateFormForMobile = (current, siteName) => {
    let valid = true;
    const newErrorsForMobile = { ...errors };
    switch (current) {
      case "name":
        if (!formData.name) {
          valid = false;
          newErrorsForMobile.name = "Name is required";
        } else {
          newErrorsForMobile.name = "";
        }
        break;

      case "email":
        if (!formData.email) {
          valid = false;
          newErrorsForMobile.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          valid = false;
          newErrorsForMobile.email = "Invalid email format";
        } else {
          newErrorsForMobile.email = "";
        }
        break;

      case "restaurantName":
        if (!formData.restaurantNumber) {
          valid = false;
          newErrorsForMobile.restaurantNumber = "Phone number is required";
        } else {
          newErrorsForMobile.restaurantNumber = "";
        }
        break;

      case "restaurantAddress1":
        if (!formData.restaurantAddress1) {
          valid = false;
          newErrorsForMobile.restaurantAddress1 =
            "Restaurant address is required";
        } else if (!formData.restaurantAddress2) {
          valid = false;
          newErrorsForMobile.restaurantAddress2 =
            "Restaurant address is required";
        } else if (!formData.pin) {
          valid = false;
          newErrorsForMobile.pin = "Zip code is required";
        } else if (!/^\d+$/.test(formData.pin)) {
          valid = false;
          newErrorsForMobile.pin = "Zip code must contain only numbers";
        } else {
          newErrorsForMobile.restaurantAddress1 = "";
        }
        break;

      case "restaurantWebsite":
        if (!formData.restaurantWebsite) {
          valid = false;
          newErrorsForMobile.restaurantWebsite =
            "Restaurant website is required";
        } else {
          newErrorsForMobile.restaurantWebsite = "";
        }
        break;

      case "deliveryType":
        if (siteName === "welcome") {
          if (!formData.deliveryType) {
            valid = false;
            newErrorsForMobile.deliveryTypeState = "Select delivery type";
          } else {
            newErrorsForMobile.deliveryTypeState = "";
          }
        } else {
          newErrorsForMobile.deliveryTypeState = "";
        }
        break;

      case "posState":
        if (siteName === "welcome") {
          if (!formData.posState) {
            valid = false;
            newErrorsForMobile.posState = "Select POS system";
          } else {
            newErrorsForMobile.posState = "";
          }

          if (formData.posState === "Other") {
            if (!formData.otherPosState) {
              valid = false;
              newErrorsForMobile.otherPosState = "POS system is required";
            } else {
              newErrorsForMobile.otherPosState = "";
            }
          } else {
            newErrorsForMobile.otherPosState = "";
          }
        } else {
          
        }
        break;

      case "preparationTime":
        if (siteName === "lacanada") {
          if (!formData.preparationTime) {
            valid = false;
            newErrorsForMobile.preparationTime =
              "Order preparation time is required";
          } else {
            newErrorsForMobile.preparationTime = "";
          }
        }
        break;
      default:
        break;
    }
    setErrors(newErrorsForMobile);
    console.log("Is form valid?", newErrorsForMobile);
    return valid;
  };

  const validateFormForMobileLa = (current, siteName) => {
    let valid = true;
    const newErrorsForMobile = { ...errors };
    switch (current) {
      case "name":
        if (!formDataLa.name) {
          valid = false;
          newErrorsForMobile.name = "Name is required";
        } else {
          newErrorsForMobile.name = "";
        }
        break;

      case "email":
        if (!formDataLa.email) {
          valid = false;
          newErrorsForMobile.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formDataLa.email)) {
          valid = false;
          newErrorsForMobile.email = "Invalid email format";
        } else {
          newErrorsForMobile.email = "";
        }
        break;

      case "restaurantName":
        if (!formDataLa.restaurantNumber) {
          valid = false;
          newErrorsForMobile.restaurantNumber = "Phone number is required";
        } else {
          newErrorsForMobile.restaurantNumber = "";
        }
        break;

      case "restaurantAddress1":
        if (!formDataLa.restaurantAddress1) {
          valid = false;
          newErrorsForMobile.restaurantAddress1 =
            "Restaurant address is required";
        } else if (!formDataLa.restaurantAddress2) {
          valid = false;
          newErrorsForMobile.restaurantAddress2 =
            "Restaurant address is required";
        } else if (!formDataLa.pin) {
          valid = false;
          newErrorsForMobile.pin = "Zip code is required";
        } else if (!/^\d+$/.test(formDataLa.pin)) {
          valid = false;
          newErrorsForMobile.pin = "Zip code must contain only numbers";
        } else {
          newErrorsForMobile.restaurantAddress1 = "";
        }
        break;

      case "restaurantWebsite":
        if (!formDataLa.restaurantWebsite) {
          valid = false;
          newErrorsForMobile.restaurantWebsite =
            "Restaurant website is required";
        } else {
          newErrorsForMobile.restaurantWebsite = "";
        }
        break;

      case "deliveryType":
        if (siteName === "welcome") {
          if (!formDataLa.deliveryType) {
            valid = false;
            newErrorsForMobile.deliveryTypeState = "Select delivery type";
          } else {
            newErrorsForMobile.deliveryTypeState = "";
          }
        } else {
          newErrorsForMobile.deliveryTypeState = "";
        }
        break;

      case "posState":
        if (siteName === "welcome") {
          if (!formDataLa.posState) {
            valid = false;
            newErrorsForMobile.posState = "Select POS system";
          } else {
            newErrorsForMobile.posState = "";
          }

          if (formDataLa.posState === "Other") {
            if (!formDataLa.otherPosState) {
              valid = false;
              newErrorsForMobile.otherPosState = "POS system is required";
            } else {
              newErrorsForMobile.otherPosState = "";
            }
          } else {
            newErrorsForMobile.otherPosState = "";
          }
        } else {
          
        }
        break;

      case "preparationTime":
        if (siteName === "lacanada") {
          if (!formDataLa.preparationTime) {
            valid = false;
            newErrorsForMobile.preparationTime =
              "Order preparation time is required";
          } else {
            newErrorsForMobile.preparationTime = "";
          }
        }
        break;
      default:
        break;
    }
    setErrors(newErrorsForMobile);
    // console.log("Is form valid?", newErrorsForMobile);
    return valid;
  };

  return [formData, setFormData, formDataLa, setFormDataLa,  errors, validateForm, validateFormForMobile, validateFormLa, validateFormForMobileLa];
};

export default useUserForm;
