import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Slider } from "antd";
import debounce from "lodash/debounce";

import Tick from "../../images/tick.png";

import useWindowResize from "../../onbording/hooks/onbording/static/useWindowResize";

const Index = ({ handleFocus }) => {
  const getPopupContainer = () => document.body;
  const { screenWidth, screenHeight } = useWindowResize();

  const [ordersNumber, setOrdersNumber] = useState(25);
  const [costPerOrder, setCostPerOrder] = useState(1.95);
  const [totalPerMonth, setTotalPerMonth] = useState();
  const [youSave, setYouSave] = useState();

  const onChange = (newValue) => {
    if (newValue < 25) {
      newValue = 25; // Set the minimum value to 25
    }
    setOrdersNumber(newValue);
  };

  const calculateCosts = debounce((newValue) => {
    let costPerOrderUnitPrice = 0;
    if (newValue >= 25 && newValue <= 50) {
      costPerOrderUnitPrice = 1.99;
    } else if (newValue >= 51 && newValue <= 100) {
      costPerOrderUnitPrice = 1.9;
    } else if (newValue >= 101 && newValue <= 150) {
      costPerOrderUnitPrice = 1.8;
    } else if (newValue >= 151 && newValue <= 200) {
      costPerOrderUnitPrice = 1.7;
    } else if (newValue >= 201 && newValue <= 250) {
      costPerOrderUnitPrice = 1.6;
    }

    const costPerOrderUnit = costPerOrderUnitPrice.toFixed(2);
    const totalperMonthCal = parseInt((newValue * costPerOrderUnit).toFixed(2));
    const saved = parseInt(((30 / 100) * (newValue * 40)).toFixed(2));

    setCostPerOrder(costPerOrderUnit);
    setTotalPerMonth(totalperMonthCal);
    setYouSave(saved);
  }, 300); // Adjust debounce delay as needed

  useEffect(() => {
    calculateCosts(ordersNumber);
  }, [ordersNumber, calculateCosts]);

  const handleFreeTrail = () => {
    handleFocus();
  };

  const handletoRestogpt = () => {
    window.open("https://restogpt.ai", "_blank");
  };

  return (
    <>
      <Container>
        <Row>
          {screenWidth > 992 ? (
            <>
              <Row>
                <Col lg="12">
                  <div className="price-slider">
                    <Slider
                      value={ordersNumber}
                      min={1}
                      max={250}
                      onChange={onChange}
                      tipFormatter={(value) => `${value} orders`}
                      tooltip={{
                        open: true,
                        placement: "top",
                        getPopupContainer,
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="cost-container">
                    <div className="cost-content">
                      <div className="cost-item">
                        <p className="cost-name">Cost per order:</p>
                        <p className="cost-number">${costPerOrder}*</p>
                      </div>
                      <div className="cost-item">
                        <p className="cost-name">Total per month:</p>
                        <p className="cost-number">${totalPerMonth}*</p>
                      </div>
                      <div className="cost-item">
                        <p className="cost-name">You save:</p>
                        <p className="cost-number">${youSave}**</p>
                      </div>
                    </div>
                    <div className="cost-sample-container">
                      <p className="cost-sample">
                        <span>*</span>fee can be passed to customer
                      </p>
                      <p className="cost-sample nextline">
                        <span>**</span>no commission
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="slider-container-mobile">
                <Row>
                  <Col lg="12">
                    <div className="qty-container">
                      <p className="qty">Qty of orders:</p>
                      <p className="qty-val">{ordersNumber}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="price-slider">
                      <Slider
                        value={ordersNumber}
                        min={1}
                        max={250}
                        onChange={onChange}
                        // tipFormatter={(value) => `${value} orders`}
                        tooltip={{
                          formatter: null,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="cost-container">
                      <div className="cost-content">
                        <div className="cost-item">
                          <div className="cost-item-group">
                            <p className="cost-name">Cost per order:</p>
                            <p className="cost-number">${costPerOrder}*</p>
                          </div>
                          <p className="cost-sample">
                            <span>*</span>fee can be passed to customer
                          </p>
                        </div>
                        <div className="cost-item">
                          <div className="cost-item-group">
                            <p className="cost-name">Total per month:</p>
                            <p className="cost-number">${totalPerMonth}*</p>
                          </div>
                        </div>
                        <div className="cost-item">
                          <div className="cost-item-group">
                            <p className="cost-name">You save:</p>
                            <p className="cost-number">${youSave}**</p>
                          </div>
                          <p className="cost-sample nextline">
                            <span>**</span> No Commission
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {/* <Row>
            <Col lg="12"> */}
              <div className="trail-section">
                <div className="trail-container">
                  <div className="trail-content">
                    <div className="trail-price">
                      <div className="price-box">
                        <img src={Tick} alt="tick" />
                        <p>$1 Setup Fee</p>
                      </div>
                      <div className="price-box">
                        <img src={Tick} alt="tick" />
                        <p>No Monthly Fee</p>
                      </div>
                      <div className="price-box">
                        <img src={Tick} alt="tick" />
                        <p>No Hidden Fees</p>
                      </div>
                      <div className="price-box">
                        <img src={Tick} alt="tick" />
                        <p>No Contract</p>
                      </div>
                    </div>
                    <div className="price-btn-container">
                      <button className="price-btn" onClick={handleFreeTrail}>
                        Start 30-Day Trial <br />
                        <span>no commitment</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            {/* </Col>
          </Row> */}
        </Row>
      </Container>
    </>
  );
};

export default Index;
