import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import NewsHeader from "../newsHead";
import Footer from "../../footer/index";
import N from "../../images/social/n.svg";
import N2 from "../../images/social/n2.svg";
import N3 from "../../images/social/n3.svg";
import N4 from "../../images/social/n4.svg";
import { newsData } from "../newsData";

const Index = () => {
  let filterNews = [];
  let { id } = useParams();
  const navigate = useNavigate();

  const [filterNewsData, setFilterNewsData] = React.useState({});

  filterNews = newsData.filter((item) => item.id == id);

  React.useEffect(() => {
    setFilterNewsData(filterNews[0]);
  }, [id]);

  const handleSelectNews = (filterId) => {
    filterNews = newsData.filter((item) => item.id == filterId);
    setFilterNewsData(filterNews[0]);
    navigate(`/news-detail/${filterId}`);
  };

  return (
    <>
      <div className="news-wrapper">
        <NewsHeader />
        <div className="news-wrapper-content">
          <div className="news-container">
            <Container>
              <Row>
                <Col xs={12} sm={9}>
                  <Card
                    style={{
                      marginBottom: "35px",
                      border: "2px solid #EEE",
                    }}
                  >
                    <Card.Body className="card-body">
                      <div className="news-detail-header">
                        <h3 className="publisher-name">
                          {filterNewsData.publisher_name}
                        </h3>
                        <div className="social-icons">
                            <a href="#"><img src={N4} alt="" width={30} height={30}/></a>
                            <a href="#"><img src={N3} alt="" width={30} height={30}/></a>
                            <a href="#"><img src={N} alt="" width={30} height={30}/></a>
                            <a href="#"><img src={N2} alt="" width={30} height={30}/></a>
                        </div>
                      </div>
                      <Card.Title className="card-title-detail">
                        {filterNewsData.title}
                      </Card.Title>
                      <p className="time">{filterNewsData.created_at}</p>
                      <Card.Text className="card-desc">
                        {filterNewsData.description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} sm={3}>
                  <Card style={{ border: "none", background: "transparent" }}>
                    <Card.Body>
                      <Card.Title className="list-head">Recent News</Card.Title>
                      <Card.Text className="lists">
                        {newsData.map((item, index) => {
                          return (
                            <p
                              key={item.id}
                              onClick={() => handleSelectNews(item.id)}
                            >
                              <span>{">> "}</span>
                              {item.title}
                            </p>
                          );
                        })}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
