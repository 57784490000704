import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Logo from "../images/logo.svg";

const NewsHead = () => {
  return (
    <>
      <div className="header">
        <Navbar collapseOnSelect expand="lg">
          <Container fluid className="justify-content-between">
            <Navbar.Brand href="/" className="logo">
              <img src={Logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-center center"
            >
              <Nav className="menu">
                <Nav.Link href="https://restogpt.ai/#WORKS">HOW IT WORKS</Nav.Link>
                <Nav.Link href="https://restogpt.ai/#FEATURES">FEATURES</Nav.Link>
                <Nav.Link href="https://restogpt.ai/#BENEFITS">BENEFITS</Nav.Link>
                <Nav.Link href="https://restogpt.ai/#CASESTUDIES">CASE STUDIES</Nav.Link>
                <Nav.Link href="https://restogpt.ai/#PRICE">PRICE</Nav.Link>
                <Nav.Link
                  href="https://restogpt.ai/#GENERATE"
                  className="gen-btn"
                  // onClick={handleFocus}
                >
                  GENERATE
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <div className="header-text">
            <h3>RESTOGPT AI IN THE NEWS</h3>
            <p>
            Find relevant press and news regarding RestoGPT AI here.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewsHead;
