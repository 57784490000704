import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import InputHints from "react-input-hints";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {address: this.props.restaurantAddress || "" };
  }

  componentDidMount() {
    var restaurantAddressFocus = document.getElementById("restaurantAddress");
    restaurantAddressFocus.focus();
  }

  componentDidUpdate(prevProps) {
    // Update the address in the state if the restaurantAddress prop changes
    if (this.props.restaurantAddress !== prevProps.restaurantAddress) {
      this.setState({ address: this.props.restaurantAddress || "" });
    }
  }

  handleChange = (address) => {
    this.setState({ address });

    this.props.callback(address);
    // this.props.setErrorMessage("");
  };

  handleKeypress = (e) => {
    if (e.key === "Enter" && this.props.isRestaurantAddressSelected) {
      const obj = { key: e.key };
      this.props.handleKeypress(e, this.props.current, this.props.next);
    }
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.props.setLocation({
          address: address,
          latitude: lat,
          longitude: lng,
        });
        this.props.callback(address);
        // Remove the next line if not needed in your logic
        this.props.setIsRestaurantAddressSelected(true);
        this.setState({ address });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  render() {
    const center = { lat: 50.064192, lng: -130.605469 };
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const searchOptions = {
      bounds: defaultBounds,
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ["address"],
    };
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: this.props.placeholder,
                className: this.props.className,
                id: this.props.name,
              })}
              id="restaurantAddress"
              type="text"
              //   value={this.props.restaurantAddress}
              value={this.state.address}
              name={this.props.restaurantAddress}
              placeholders={this.props.placeholder}
              className={this.props.className}
              onKeyDown={this.handleKeypress}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div style={{ color: "black" }}>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "# background-color: rgba(0, 0, 0,0.3)",
                      cursor: "pointer",
                      padding: "5px",
                      color: "#ddd",
                      fontSize: "14px",
                    }
                  : {
                      backgroundColor: "# background-color: rgba(0, 0, 0,0.3)",
                      cursor: "pointer",
                      padding: "5px",
                      color: "#fff",
                      fontSize: "14px",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.description}
                  >
                    <span style={{ color: "black" }}>
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
