import React, { useState } from "react";
import InputHints from "react-input-hints";
import { ArrowRightShort } from "react-bootstrap-icons";
import UseOnly from "../images/us-only.svg";
const ResWebsite = ({
  restaurantWebsite,
  setRestaurantWebsite,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
}) => {

  React.useEffect(() => {
    var restaurantWebsiteFocus = document.getElementById("restaurantWebsite");
    restaurantWebsiteFocus.focus();
  }, []);
  return (
    <>
      <div className="form-box">
        <p>
          4<ArrowRightShort />
          {/* 2<ArrowRightShort /> */}
          Enter restaurant website [if any] <img src={UseOnly} />
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <InputHints
            type="text"
            id="restaurantWebsite"
            placeholders={["e.g. www.stellaspizzakitchen.com"]}
            className="form-control"
            value={restaurantWebsite}
            onChange={(val) => {
              setRestaurantWebsite(val.target.value);
              setErrorMessage("");
            }}
            onKeyPress={(e) => handleKeypress(e, current, next)}
          />
        </form>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
      </div>
    </>
  );
};

export default ResWebsite;
