import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightShort } from "react-bootstrap-icons";
import useFormOne from "../../hooks/onbording/user/new/useFormOne";
import useLocalStorageDelivery from "../../hooks/onbording/user/new/useLocalStorageDelivery";
import submitData from "../../hooks/onbording/user/new/submitData";
import {
  deliveryTypeOption,
  posOption,
  preparationTime,
} from "../../selectOption";

const MobileDelivery = ({ setComponent, siteName }) => {
  const [formData, setFormData, errors, validateForm] = useFormOne();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorageDelivery("formDataOne");

  let decodedValue = "";
  let convertedText = "";

  // Get the current URL
  const currentUrl = window.location.href;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("root")) {
    const encodedValue = currentUrl.split("=")[1];

    // Decode the base64-encoded value
    decodedValue = atob(decodeURIComponent(encodedValue));
    convertedText = decodedValue.split("-").join(" ");

    // console.log("decodedValue-----------", decodedValue);
  } else {
    decodedValue = "";
    // console.log("No 'root' parameter in the URL.");
  }

  let url = `https://restogpt.ai/${decodedValue}`;
  localStorage.setItem("storefront_link",url);
  localStorage.setItem("storefront_url_name",decodedValue);

  React.useEffect(() => {
    localStorage.setItem("siteName", siteName);
  }, []);

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    // console.log("lastFilledComponent", lastFilledComponent);
    if (lastFilledComponent) {
      setFormData({
        name: lastFilledComponent.name || "",
        email: lastFilledComponent.email || "",
        restaurantNumber: lastFilledComponent.restaurantNumber || "",
        restaurantAddress1: lastFilledComponent.restaurantAddress1 || "",
        // restaurantAddress2: lastFilledComponent.restaurantAddress2 || "",
        // pin: lastFilledComponent.pin || "",
        restaurantWebsite: lastFilledComponent.restaurantWebsite || "",
        deliveryType: lastFilledComponent.deliveryType || "",
        posState: lastFilledComponent.posState || "",
        otherPosState: lastFilledComponent.otherPosState || "",
        storefrontDomain: lastFilledComponent.storefrontDomain || "",
        customerDatabase: lastFilledComponent.customerDatabase || "",
      });
    }
  }, [setFormData]);

  const handleInputChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    handleInputChangeWithStorageUpdate({
      target: { name, value: numericValue },
    });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (validateForm(siteName)) {
      submitData(formData, siteName, decodedValue, convertedText);
      localStorage.clear();
      setComponent("thanks");
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };

  const onNext = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setComponent("formtwo");
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };
  return (
    <>
      <div className="mobile-form-container">
        <Container>
          <Row>
            <Col>
              <div className="head-title">
                <h3>Let’s get started</h3>
              </div>
              {decodedValue !== "" && (
                <div className="right-header-text">
                  {/* <p className="right-header-text-p">{convertedText}</p> */}
                  <a href={url} target="_blank">
                    {url}
                  </a>
                </div>
              )}
              <form class="styled-form">
                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="name">
                      1<ArrowRightShort />
                      Your full name<span className="impt">*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="name"
                      name="name"
                      placeholder="e.g. Robert Cooperman"
                      value={formData.name}
                      onChange={handleInputChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.name}</span>
                  </div>
                </div>
                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="email">
                      2<ArrowRightShort />
                      Your email<span className="impt">*</span>
                    </label>
                    <input
                      type="email"
                      className="input-form"
                      id="email"
                      name="email"
                      placeholder="stellas@gmail.com"
                      value={formData.email}
                      onChange={handleInputChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.email}</span>
                  </div>
                </div>
                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="restaurantNumber">
                      3<ArrowRightShort />
                      Your phone number<span className="impt">*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="restaurantNumber"
                      name="restaurantNumber"
                      placeholder="e.g. 123456789"
                      value={formData.restaurantNumber}
                      onInput={handleNumberInputChange}
                      required
                    />
                    <span className="error">{errors.restaurantNumber}</span>
                  </div>
                </div>
                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="restaurant-address">
                      4<ArrowRightShort />
                      Restaurant address<span className="impt">*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="restaurantAddress1"
                      name="restaurantAddress1"
                      placeholder="e.g. 1010 Wilshire Blvd"
                      value={formData.restaurantAddress1}
                      onChange={handleInputChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.restaurantAddress1}</span>
                  </div>
                </div>
                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="restaurantWebsite">
                      5<ArrowRightShort />
                      Restaurant website<span className="impt">*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="restaurantWebsite"
                      name="restaurantWebsite"
                      placeholder="e.g. www.stellas.com"
                      value={formData.restaurantWebsite}
                      onChange={handleInputChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.restaurantWebsite}</span>
                  </div>
                </div>
                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="name">
                      6<ArrowRightShort />
                      What domain/subdomain would you like to use for your
                      restaurant's storefront? <span className="impt">*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="storefrontDomain"
                      name="storefrontDomain"
                      placeholder="e.g. order.yourdomain.com"
                      value={formData.storefrontDomain}
                      onChange={handleInputChangeWithStorageUpdate}
                    />
                    <span className="error">{errors.storefrontDomain}</span>
                  </div>
                </div>

                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="posState">
                      7<ArrowRightShort />
                      Which POS system does your restaurant use?
                      <span className="impt">*</span>
                    </label>
                    <select
                      className="input-form"
                      id="posState"
                      name="posState"
                      onChange={handleInputChangeWithStorageUpdate}
                      value={formData.posState}
                    >
                      <option value="">Please select</option>
                      {posOption.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    <span className="error">{errors.posState}</span>
                  </div>
                </div>
                {formData.posState === "Other" ? (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        className="input-form"
                        id="otherPosState"
                        name="otherPosState"
                        placeholder="enter POS type"
                        value={formData.otherPosState}
                        onChange={handleInputChangeWithStorageUpdate}
                        required
                      />
                      <span className="error">{errors.otherPosState}</span>
                    </div>
                  </>
                ) : null}

                {/* <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="name">
                      8<ArrowRightShort />
                      Is there a customer database?{" "}
                      <span className="impt">*</span>
                    </label>
                    <div className="radio-btn-group">
                      <div className="radio-btn">
                        <input
                          type="radio"
                          name="customerDatabase"
                          value="yes"
                          className="radio-input"
                          onChange={handleInputChangeWithStorageUpdate}
                          checked={formData.customerDatabase === "yes"}
                        />
                        <span className="radio-text">Yes</span>
                      </div>
                      <div className="radio-btn">
                        <input
                          type="radio"
                          name="customerDatabase"
                          value="No"
                          className="radio-input"
                          onChange={handleInputChangeWithStorageUpdate}
                          checked={formData.customerDatabase === "No"}
                        />
                        <span className="radio-text">No</span>
                      </div>
                    </div>
                    <span className="error">{errors.customerDatabase}</span>
                  </div>
                </div> */}

                <div class="form-group mobile-form">
                  <div className="input-grouped">
                    <label htmlFor="deliveryType">
                      8<ArrowRightShort />
                      What delivery type does your restaurant want to use?
                      <span className="impt">*</span>
                    </label>
                    <select
                      className="input-form"
                      id="deliveryType"
                      name="deliveryType"
                      onChange={handleInputChangeWithStorageUpdate}
                      value={formData.deliveryType}
                      // style={selectStyle}
                    >
                      <option value="" style={{ color: "#C8BFE5" }}>
                        Please select
                      </option>
                      {deliveryTypeOption.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    <span className="error">{errors.deliveryTypeState}</span>
                  </div>
                </div>
                <div className="btn-form-container">
                  <button className="form-btn" onClick={onNext}>
                    Next
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MobileDelivery;
