import React, { useState, useEffect } from 'react';

function TypewriterEffect({ modalTextDelay1, texts, up }) {
 
  const speed = 50;
  const cursor = "|";

  const [textElements, setTextElements] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [typingComplete, setTypingComplete] = useState(false);

  useEffect(() => {
    let intervalId;

    const typeNextText = () => {
      const currentSentence = texts[currentTextIndex];
      let currentIndex = 0;

      intervalId = setInterval(() => {
        if (currentIndex <= currentSentence.length) {
          setTextElements(prevTextElements => {
            const newTextElements = [...prevTextElements];
            newTextElements[currentTextIndex] = currentSentence.substring(0, currentIndex) + cursor;
            return newTextElements;
          });
          currentIndex++;
        } else {
          clearInterval(intervalId);
          setTypingComplete(true);
        }
      }, speed);
    };

    if (modalTextDelay1 === "done" && currentTextIndex < texts.length && !typingComplete) {
      typeNextText();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [modalTextDelay1, currentTextIndex, typingComplete]);

  useEffect(() => {
    if (modalTextDelay1 === "done" && currentTextIndex < texts.length && typingComplete) {
      setTextElements(prevTextElements => {
        const newTextElements = [...prevTextElements];
        newTextElements[currentTextIndex] = newTextElements[currentTextIndex].replace(cursor, '');
        return newTextElements;
      });
      setCurrentTextIndex(prevIndex => prevIndex + 1);
      setTypingComplete(false);
    }
  }, [modalTextDelay1, currentTextIndex, texts, typingComplete]);

  return (
    <>
    {modalTextDelay1 === "done" && (
      <div className="css-typing-bottom">
        <ul className={up ? "custom-list" : ""}>
          {textElements.map((text, index) => (
            // <li key={index} style={{ overflowWrap: 'break-word', marginBottom: index === 0 ? '20px' : '0' }} dangerouslySetInnerHTML={{ __html: text }}></li>
            <li key={index} style={{ overflowWrap: 'break-word', marginBottom: (index === 0 && up) ? '20px' : ''}} dangerouslySetInnerHTML={{ __html: text }}></li>
          ))}
        </ul>
      </div>
    )}
    </>
  );
}

export default TypewriterEffect;
