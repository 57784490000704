import React from "react";
import LocationSearchInput from "./LocationSearchInput";
import { ArrowRightShort } from "react-bootstrap-icons";
import UseOnly from '../images/us-only.svg';

const ResAddress = ({
  restaurantAddress,
  setRestaurantAddress,
  isRestaurantAddressSelected,
  setIsRestaurantAddressSelected,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
  setLocation,
}) => {

  return (
    <>
      <div className="form-box">
        <p>
          5<ArrowRightShort />
          {/* 4<ArrowRightShort /> */}
          Enter restaurant address<span>*</span> <img src={UseOnly}/>
        </p>
        <LocationSearchInput
          placeholder="456 Foothill Blvd, La Cañada Flintridge, CA 91011"
          className="form-control"
          callback={setRestaurantAddress}
          setErrorMessage={setErrorMessage}
          setIsRestaurantAddressSelected={setIsRestaurantAddressSelected}
          restaurantAddress={restaurantAddress}
          isRestaurantAddressSelected={isRestaurantAddressSelected}
          handleKeypress={handleKeypress}
          current={current}
          next={next}
          setLocation={setLocation}
        />

        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
        {/* <p className="comment-l">* US Only </p> */}
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default ResAddress;
