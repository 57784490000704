import React from "react";
import StartPage from "./StartPage";
import FormStartPage from "./FormStartPage";
import ThanksPage from "./thanks";
import StepPage from "./Top";
import FormPage from "./FormPage";
const Index = ({ siteName, companyInfo }) => {
  console.log("siteName----",siteName);
  const [showPage, setShowPage] = React.useState("startPage");
  return (
    <>
      {showPage === "startPage" ? (
        <StartPage setShowPage={setShowPage} />
      ) : showPage === "formPage" ? (
        <FormStartPage setShowPage={setShowPage} siteName={siteName} />
      ) : showPage === "thanksPage" ? (
        <ThanksPage />
      ) : null}
    </>
  );
};

export default Index;
