import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Buffer = () => {
    const navigate = useNavigate();
  const [apiCalled, setApiCalled] = useState(false);
  const [apiCalledMount, setApiCalledMount] = useState(false);

  useEffect(() => {
    if (!apiCalledMount) {
      sendDataToOurServer();
      setApiCalledMount(true);
    }
    // sendDataToOurServer();
  }, [apiCalledMount]);

  const sendDataToOurServer = async () => {
    setApiCalled(true);
    const urlWeb = window.location.href;
    const params = new URLSearchParams(new URL(urlWeb).search);
    const paymentIntentId = params.get("payment_intent");

    const url = "https://orderbyte.io/api/subscription/v1/payment/create";
    const headers = {
      auth: "order_xht4sAytlopr8hn_byte",
      "Content-Type": "application/json",
    };
    const data = {
      id: paymentIntentId,
      status: "completed",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to create payment");
      }

      const result = await response.json();
    //   console.log("Payment created:", result);
      // Handle the payment intent response here
      if (result) {
        setApiCalled(false);
        navigate("/thank-you");
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
      // Handle the error here
    }
  };

  return (
    <div>
      {" "}
      {apiCalled && (
        <div className="page-loader">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default Buffer;
