import React, { useState } from "react";
import {Modal, Row, Col, Button} from "react-bootstrap";
import CloseIcon from "../../images/close-icon.png";
import crm1 from "../../images/popup/crm/crm1.png";
import crm2 from "../../images/popup/crm/crm2.png";
import crm3 from "../../images/popup/crm/crm3.png";
import crm4 from "../../images/popup/crm/crm4.png";
import crm5 from "../../images/popup/crm/crm5.png";
import crm6 from "../../images/popup/crm/crm6.png";
import crm7 from "../../images/popup/crm/crm7.png";
import crm8 from "../../images/popup/crm/crm8.png";
import crm9 from "../../images/popup/crm/crm9.png";

function StorefrontModal() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <div className="more-container">
        <a className="btun"  onClick={handleShow}>LEARN MORE</a>
        </div>
  
        <Modal show={show} onHide={handleClose} size="lg" centered className="AI-modal">
          
          <Modal.Body>
            <Button className="close-btn" onClick={handleClose}>
              <img src={CloseIcon}/>
            </Button>
            <Row>
              <Col lg="12">
                <div className="item">
                    <img src={crm1}/>
                    <p>Customer database <span style={{color: "#5a00ca"}}>[structured]</span></p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm2}/>
                    <p>Review management <span style={{color: "#5a00ca"}}>[AI]</span></p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm3}/>
                    <p>CRM <span style={{color: "#5a00ca"}}>[segmented data]</span></p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm4}/>
                    <p>Loyalty, marketing, remarketing tools</p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm5}/>
                    <p>Email and SMS automation</p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm6}/>
                    <p>Campaign content template editor</p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm7}/>
                    <p>Customer engagement tools</p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm8}/>
                    <p>Automated order review requests</p>
                </div>
              </Col>
              <Col lg="12">
                <div className="item">
                    <img src={crm9}/>
                    <p>Promo and QR code generator</p>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          
        </Modal>
      </>
    );
  }
  
  export default StorefrontModal;