import React from "react";
import Logo from "../../../images/onbordinglogo.png";
import Contact from "../../../images/onbording-contact-icon.svg";

const Thanks = ({showText}) => {
  return (
    <>
      <div className="right-container">
      <div className="right-header">
            <a href="https://getorderbyte.com/" className="logo">
              {/* <img src={Logo} /> */}
            </a>
            <a href="mailto:hello@restogpt.ai">
              <span className="icon">
                <img src={Contact} />
              </span>
            </a>
          </div>
        <div className="right-wrapper">
          <div className="thanks-container">
            <h3 style={{marginBottom: 0}}>Thanks!</h3>
            <h3>Your response was submitted.</h3>
            {showText ? "" : (
               <p>
               In the next 24 hours, your AI storefront will <br /> be completed and you
               will receive an email <br /> to join your private chat group and go live.
             </p>
            )}
            <a href="https://getorderbyte.com/" className="logo" style={{marginTop: "70px"}}>
              <img src={Logo} />
            </a>
            <p className="thanks-p"><span>The Future </span>of Online Ordering is AI</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thanks;
