import React, { useState } from "react";
import { ArrowRightShort } from "react-bootstrap-icons";

const ResColor = ({
  restaurantColor,
  setRestaurantColor,
  restaurantColorChecked,
  setRestaurantColorChecked,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next
}) => {
  const [yellow, setYellow] = useState(true);
  const [orange, setOrange] = useState(true);
  const [red, setRed] = useState(true);
  const [green, setGreen] = useState(true);
  const [black, setBlack] = useState(true);
  let canSendResponse = false;
  const myFunction = (event) => {
    handleKeypress(event,current,next)
  };
  const keyDownHandler = event => {
    if (event.key === 'Enter' && next === "next6" && canSendResponse) {
      event.preventDefault();
      myFunction(event);
    }
    // if (event.key === 'Enter' && next === "next5" && canSendResponse) {
    //   event.preventDefault();
    //   myFunction(event);
    // }
    canSendResponse = true
  };

  React.useEffect(() => {

    // const keyDownHandler = event => {
    //   if (event.key === 'Enter' && next === "next5" && canSendResponse) {
    //     event.preventDefault();
    //     myFunction(event);
    //   }
    //   canSendResponse = true
    // };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handleChange = (color) => {
    setRestaurantColor(color);
    localStorage.setItem("color",color)
  }
  
  return (
    <>
      <div className="form-box">
        <p>
          6<ArrowRightShort />
          {/* 5<ArrowRightShort /> */}
          Select your preferred color<span>*</span>
        </p>
        <div className="color-box">
          <label className="custom-check">
            <input
              type="checkbox"
              checked={restaurantColor === "#F9E000"}
              value={yellow}
              onChange={() => handleChange("#F9E000")}
            />
            <span style={{ background: "#F9E000" }}></span>
          </label>
          <label className="custom-check">
            <input
              type="checkbox"
              value={orange}
              checked={restaurantColor === "#FF9900"}
              onChange={() => handleChange("#FF9900")}
            />
            <span style={{ background: "#FF9900" }}></span>
          </label>
          <label className="custom-check">
            <input
              type="checkbox"
              value={red}
              checked={restaurantColor === "#AD002A"}
              onChange={() => handleChange("#AD002A")}
            />
            <span style={{ background: "#AD002A" }}></span>
          </label>
          <label className="custom-check">
            <input
              type="checkbox"
              value={green}
              checked={restaurantColor === "#80FF00"}
              onChange={() => handleChange("#80FF00")}
            />
            <span style={{ background: "#80FF00" }}></span>
          </label>
          <label className="custom-check">
            <input
              type="checkbox"
              value={black}
              checked={restaurantColor === "#000000"}
              onChange={() => handleChange("#000000")}
            />
            <span style={{ background: "#000000" }}></span>
          </label>
        </div>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default ResColor;
