import React from "react";
import StepPage from "../mobile/Top";
import MobileDelivery from "./mobileDelivery";
import MobileCompany from "./mobileCompany";
import ThanksPage from "../mobile/thanks"
const Index = ({setShowPage, siteName}) => {
    const [showComponent, setComponent] = React.useState("deliveryForm");
    const [showText, setShowText] = React.useState(false);
  return (
    <>
      <div className="wraper">
      <StepPage/>
      {showComponent === "deliveryForm" ? (
        <MobileDelivery setComponent={setComponent} siteName={siteName}/>
      ) : showComponent === "companyForm" ? (
        <MobileCompany setComponent={setComponent} siteName={siteName} setShowText={setShowText}/>
      ) : showComponent === "thanks" ? (
        <ThanksPage showText={showText}/>
      ) : null}
      </div>
    </>
  );
};

export default Index;
