import { useState } from "react";

const useCompanyForm = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    companyType: "",
    ein: "",
    dba: "",
    companyAddress: "",
    phone: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerPhone: "",
    ownerEmail: "",
    bankName: "",
    bankAddress: "",
  });

  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [errors, setErrors] = useState({
    companyName: "",
    companyType: "",
    ein: "",
    dba: "",
    companyAddress: "",
    phone: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerPhone: "",
    ownerEmail: "",
    bankName: "",
    bankAddress: "",
    checkboxChecked: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.companyName) {
      valid = false;
      newErrors.companyName = "Legal company name is required";
    } else {
      newErrors.companyName = "";
    }

    if (!formData.companyType) {
      valid = false;
      newErrors.companyType = "Company type is required";
    } else {
      newErrors.companyType = "";
    }

    if (!formData.ein) {
      valid = false;
      newErrors.ein = "EIN is required";
    } else {
      newErrors.ein = "";
    }

    //   if (!formData.dba) {
    //     valid = false;
    //     newErrors.dba = "DBA is required";
    //   } else {
    //     newErrors.dba = "";
    //   }

    if (!formData.companyAddress) {
      valid = false;
      newErrors.companyAddress = "Company address is required";
    } else {
      newErrors.companyAddress = "";
    }

    if (!formData.phone) {
      valid = false;
      newErrors.phone = "Phone number is required";
    } else {
      newErrors.phone = "";
    }

    if (!formData.ownerFirstName) {
      valid = false;
      newErrors.ownerFirstName = "Owner's first name is required";
    } else {
      newErrors.ownerFirstName = "";
    }

    if (!formData.ownerLastName) {
      valid = false;
      newErrors.ownerLastName = "Owner's last name is required";
    } else {
      newErrors.ownerLastName = "";
    }

    if (!formData.ownerPhone) {
      valid = false;
      newErrors.ownerPhone = "Owner's phone number is required";
    } else {
      newErrors.ownerPhone = "";
    }

    if (!formData.ownerEmail) {
      valid = false;
      newErrors.ownerEmail = "Owner's email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.ownerEmail)) {
      valid = false;
      newErrors.ownerEmail = "Invalid email format";
    } else {
      newErrors.ownerEmail = "";
    }

    if (!formData.bankName) {
      valid = false;
      newErrors.bankName = "Bank name is required";
    } else {
      newErrors.bankName = "";
    }

    if (!formData.bankAddress) {
      valid = false;
      newErrors.bankAddress = "Bank address is required";
    } else {
      newErrors.bankAddress = "";
    }

    if (!checkboxChecked) {
      valid = false;
      newErrors.checkboxChecked = "You must agree to the Non-Disclosure Agreement";
    } else {
      newErrors.checkboxChecked = "";
    }

    // Add similar validation for other fields

    setErrors(newErrors);
    return valid;
  };

  return [formData, setFormData, errors, validateForm, checkboxChecked, setCheckboxChecked];
};

export default useCompanyForm;
