import React from "react";
import StepPage from "./Top";
import FormPage from "./FormPage";
import FromPageLa from "./FormPageLa";
const Index = ({setShowPage, siteName}) => {
  return (
    <>
      <div className="wraper">
      <StepPage/>
      {siteName === "lacanada" ? (
        <FromPageLa setShowPage={setShowPage} siteName={siteName}/>
      ) : (
        <FormPage setShowPage={setShowPage} siteName={siteName}/>
      )}
      </div>
    </>
  );
};

export default Index;
