import React, { useState } from "react";
import InputHints from "react-input-hints";
import { ArrowRightShort } from "react-bootstrap-icons";
import UseOnly from '../images/us-only.svg';

const ResName = ({
  restaurantName,
  setRestaurantName,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
  showCircles,
}) => {
  return (
    <>
      <div className="form-box">
        <p>
          2<ArrowRightShort />
          Enter restaurant name{""}
          <span>*</span> <img src={UseOnly}/>
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}> 
        {/* {showCircles && <div className="input-circle"></div>}  */}
        <InputHints
          type="text"
          id="restaurantName"
          placeholders={["e.g. Stella’s Pizza"]}
          className="form-control"
          value={restaurantName}
          onChange={(val) => {
            setRestaurantName(val.target.value);
            setErrorMessage("");
          }}
          onKeyPress={(e) => {
            
            handleKeypress(e,current,next)
          }}
        />
        </form>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default ResName;
