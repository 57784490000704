import React from "react";
import RestoCarousel from "../common/RestoCarousel";
// import Slider from "../../images/slider.png";

const Index = () => {
  return (
    <>
      <div className="left-container">
        <div className="left-content">
          <h3 className="onbheader">Why <span>RestoGPT AI?</span></h3>
          <p className="sub-header">YOUR RESTAURANT'S UNFAIR ADVANTAGE</p>
          <RestoCarousel />
        </div>
      </div>
    </>
  );
};

export default Index;
