import React, { useState } from "react";

const Loader = () => {
  
    return (
        <>
        <div className="page-loader">
            <div className="loader"></div>
        </div>
        </>
        );
};

export default Loader;