import { useState } from "react";

const useDeliveryForm = () => {
  const [formData, setFormData] = useState({
    orderPreparationTime: "",
    deliveryTime: "",
    deliveryCharges: "",
    stripeFee: "",
    salesTax: "",
    storefrontDomain: "",
    otherOrderPreparationTime: "",
    otherDeliveryTime: "",
    otherDeliveryCharges: "",
    otherStripeFee: "",
    otherSalesTax: "",
  });

  const [errors, setErrors] = useState({
    orderPreparationTime: "",
    deliveryTime: "",
    deliveryCharges: "",
    stripeFee: "",
    salesTax: "",
    storefrontDomain: "",
    otherOrderPreparationTime: "",
    otherDeliveryTime: "",
    otherDeliveryCharges: "",
    otherStripeFee: "",
    otherSalesTax: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.orderPreparationTime) {
      valid = false;
      newErrors.orderPreparationTime = "Order preparation time is required";
    } else if (formData.orderPreparationTime === "Other") {
      if (!formData.otherOrderPreparationTime) {
        valid = false;
        newErrors.orderPreparationTime = "Order preparation time is required";
      } else {
        newErrors.orderPreparationTime = "";
      }
    } else {
      newErrors.orderPreparationTime = "";
    }

    if (!formData.deliveryTime) {
      valid = false;
      newErrors.deliveryTime = "Delivery time is required";
    } else if (formData.deliveryTime === "Other") {
      if (!formData.otherDeliveryTime) {
        valid = false;
        newErrors.deliveryTime = "Delivery time is required";
      } else {
        newErrors.deliveryTime = "";
      }
    } else {
      newErrors.otherDeliveryTime = "";
    }

    if (!formData.deliveryCharges) {
      valid = false;
      newErrors.deliveryCharges = "Delivery charges is required";
    } else if (
      formData.deliveryCharges ===
      "Other (indicate any custom split you’d like)"
    ) {
      if (!formData.otherDeliveryCharges) {
        valid = false;
        newErrors.deliveryCharges = "Delivery charges is required";
      } else {
        newErrors.deliveryCharges = "";
      }
    } else {
      newErrors.deliveryCharges = "";
    }

    if (!formData.stripeFee) {
      valid = false;
      newErrors.stripeFee = "Stripe's credit card processing fee is required";
    } else if (
      formData.stripeFee === "Other (indicate any custom split you’d like)"
    ) {
      if (!formData.otherStripeFee) {
        valid = false;
        newErrors.stripeFee = "Stripe's credit card processing fee is required";
      } else {
        newErrors.stripeFee = "";
      }
    } else {
      newErrors.stripeFee = "";
    }

    if (!formData.salesTax) {
      valid = false;
      newErrors.salesTax = "Sales tax is required";
    } else if (formData.salesTax === "Other") {
      if (!formData.otherSalesTax) {
        valid = false;
        newErrors.salesTax = "Sales tax is required";
      } else {
        newErrors.salesTax = "";
      }
    } else {
      newErrors.salesTax = "";
    }

    if (!formData.storefrontDomain) {
      valid = false;
      newErrors.storefrontDomain = "Domain/subdomain is required";
    } else {
      newErrors.storefrontDomain = "";
    }

    // Add similar validation for other fields

    setErrors(newErrors);
    return valid;
  };

  return [formData, setFormData, errors, validateForm];
};

export default useDeliveryForm;
