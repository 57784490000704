import React, { useState } from "react";
import Select from "react-dropdown-select";
import { ArrowRightShort } from "react-bootstrap-icons";
// import UseOnly from "../images/us-only.svg";

const resTypeOptions = [
  { value: "American burgers", label: "🍔 American burgers" },
  { value: "American BBQ", label: "🍖 American BBQ" },
  { value: "Breakfast", label: "🍳 Breakfast" },
  { value: "Chicken", label: "🍗 Chicken" },
  { value: "Chinese", label: "🍜 Chinese" },
  { value: "Falafel", label: "🧆 Falafel" },
  { value: "Hot dogs", label: "🌭 Hot dogs" },
  { value: "Indian", label: "🍛 Indian" },
  { value: "Italian pizzas", label: "🍕 Italian pizzas" },
  { value: "Italian pastas", label: "🍝 Italian pastas" },
  { value: "Japanese", label: "🍣 Japanese " },
  { value: "Kebab", label: "🌯 Kebab" },
  { value: "Mediterranean", label: "🥙 Mediterranean" },
  { value: "Mexican tacos", label: "🌮 Mexican tacos" },
  { value: "Mexican burritos", label: "🌯 Mexican burritos"},
  { value: "Pan-Asian", label: "🥘 Pan-Asian"},
  { value: "Poke", label: "🍲 Poke"},
  { value: "Pastry", label: "🍰 Pastry"},
  { value: "Sandwiches/subs - deli", label: "🥪 Sandwiches/subs - deli"},
  { value: "Salads/Vegetarian", label: "🥗 Salads/Vegetarian"},
  { value: "Seafood", label: "🦞 Seafood"},
  { value: "Thai", label: "🍢 Thai"},
];

const ResType = ({
  restaurantType,
  setRestaurantType,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
}) => {
  let i = 0;
  let placeholder = "";
  const txt = "example of select";
  let speed = 120;
  const myFunction = (event) => {
    handleKeypress(event, current, next);
  };

  function type() {
    placeholder += txt.charAt(i);
    console.log("placeholder",placeholder);
    document.getElementById("restaurantType").setAttribute("placeholder",placeholder);
    i++;
    setTimeout(type,speed);
  } 
  React.useEffect(() => {
    // setTimeout(() => {
    //   type();
    // }, 1000);
   
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        myFunction(event);
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <>
      <div className="form-box">
        <p>
          3<ArrowRightShort />
          Select which best describes your food{""}
          <span>*</span> 
          {/* <img src={UseOnly} /> */}
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <select
            id="restaurantType"
            className="form-control"
            onChange={(val) => {
              localStorage.setItem("restaurant_type",val.target.value)
              setRestaurantType(val.target.value);
              setErrorMessage("");
            }}
            value={restaurantType}
          >
            <option disabled={true} value="" >
              Select Restaurant Type
            </option>
            {resTypeOptions.map((item, index) => {
              return (
                <option
                  value={item.value}
                  style={{ color: "#fff", backgroundColor: "#7700B0" }}
                  key={item.value}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
          {/* <Select
           id="restaurantType"
            value={restaurantType}
            options={resTypeOptions}
            placeholder="Select Restaurant Type"
            dropdownPosition="buttom"
            className="form-control"
            style={{ color: "#fff", fontFamily: "Roboto" }}
            // color="#fff"
            onChange={(type) => {
              let resType = type.map((type) => type.value);
              let text = resType.toString();
              setRestaurantType(text);
              localStorage.setItem("restaurant_type", text);
              setErrorMessage("");
            }}
          /> */}
        </form>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default ResType;
