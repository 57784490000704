export const deliveryTypeOption = [
  { value: "On-demand drivers", label: "On-demand drivers" },
  { value: "Own drivers", label: "Own drivers" },
  { value: "Hybrid", label: "Hybrid (both)" },
];

export const posOption = [
  { value: "Square", label: "Square" },
  { value: "Toast", label: "Toast" },
  { value: "Clover", label: "Clover" },
  { value: "Revel", label: "Revel" },
  { value: "Aloha", label: "Aloha" },
  { value: "Other", label: "Other" },
  { value: "None", label: "None" },
];

export const preparationTime = [
  { value: "15 min", label: "15 min" },
  { value: "20 min", label: "20 min" },
  { value: "25 min", label: "25 min" },
  { value: "Other", label: "Other" },
]

export const companyTypeOption = [
  { value: "Sole proprietorship", label: "Sole proprietorship" },
  { value: "Single-member LLC", label: "Single-member LLC" },
  { value: "Multi-member LLC", label: "Multi-member LLC" },
  { value: "Private partnership", label: "Private partnership" },
  { value: "Private corporation", label: "Private corporation" },
  { value: "Other / I’m not sure", label: "Other / I’m not sure" },
]

export const resTypeOptions = [
  { value: "American burgers", label: "🍔 American burgers" },
  { value: "American BBQ", label: "🍖 American BBQ" },
  { value: "Breakfast", label: "🍳 Breakfast" },
  { value: "Chicken", label: "🍗 Chicken" },
  { value: "Chinese", label: "🍜 Chinese" },
  { value: "Falafel", label: "🧆 Falafel" },
  { value: "Hot dogs", label: "🌭 Hot dogs" },
  { value: "Indian", label: "🍛 Indian" },
  { value: "Italian pizzas", label: "🍕 Italian pizzas" },
  { value: "Italian pastas", label: "🍝 Italian pastas" },
  { value: "Japanese", label: "🍣 Japanese " },
  { value: "Kebab", label: "🌯 Kebab" },
  { value: "Mediterranean", label: "🥙 Mediterranean" },
  { value: "Mexican tacos", label: "🌮 Mexican tacos" },
  { value: "Mexican burritos", label: "🌯 Mexican burritos"},
  { value: "Pan-Asian", label: "🥘 Pan-Asian"},
  { value: "Poke", label: "🍲 Poke"},
  { value: "Pastry", label: "🍰 Pastry"},
  { value: "Sandwiches/subs - deli", label: "🥪 Sandwiches/subs - deli"},
  { value: "Salads/Vegetarian", label: "🥗 Salads/Vegetarian"},
  { value: "Seafood", label: "🦞 Seafood"},
  { value: "Thai", label: "🍢 Thai"},
];
