import React from "react";
import StepPage from "./MobileTop";
import FormMobile from "./formMobile";
import FormMobileTwo from "./formMobileTwo";
import ThanksPage from "../mobile/thanks";
const Index = ({ setShowPage, siteName }) => {
  const [showComponent, setComponent] = React.useState("form");
  const [showText, setShowText] = React.useState(false);
  return (
    <>
      <div className="wraper">
        <StepPage />
        {showComponent === "form" ? (
          <FormMobile setComponent={setComponent} siteName={siteName} />
        ) : showComponent === "formtwo" ? (
          <FormMobileTwo
            setComponent={setComponent}
            siteName={siteName}
            setShowText={setShowText}
          />
        ) : showComponent === "thanks" ? (
          <ThanksPage showText={showText} />
        ) : null}
      </div>
    </>
  );
};

export default Index;
