import React from "react";
import NonDisclosureModal from "../deliveryRight/nonDisclosureModal";
import LaunchModal from "./launchModal";
import { useParams } from "react-router-dom";
import Logo from "../../../images/onbordinglogo.png";
import Contact from "../../../images/onbording-contact-icon.svg";
import { ArrowRightShort } from "react-bootstrap-icons";
import useFormTwo from "../../hooks/onbording/user/new/useFormTwo";
import useLocalStorageFormTwo from "../../hooks/onbording/user/new/useLocalStorageFormTwo";
import { companyTypeOption } from "../../selectOption";
import oneFormSubmitData from "../../hooks/onbording/user/new/oneFormSubmitData";

const FormDesktop = ({ setShowForm, siteName }) => {
  const [
    formData,
    setFormData,
    errors,
    validateForm,
    checkboxChecked,
    setCheckboxChecked,
  ] = useFormTwo();
  const { saveToLocalStorage, getFromLocalStorage } =
  useLocalStorageFormTwo("formDataTwo");
    const [openModal, setOpenModal] = React.useState(false);
    const [openLaunchModal, setLaunchModal] = React.useState(false);


  let decodedValue = "";
  let convertedText = "";

  // Get the current URL
  const currentUrl = window.location.href;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("root")) {
    const encodedValue = currentUrl.split("=")[1];

    // Decode the base64-encoded value
    decodedValue = atob(decodeURIComponent(encodedValue));
    convertedText = decodedValue.split("-").join(" ");

    // console.log("decodedValue-----------", decodedValue);
  } else {
    decodedValue = "";
    // console.log("No 'root' parameter in the URL.");
  }

  let url = `https://restogpt.ai/${decodedValue}`;

  React.useEffect(() => {
    localStorage.setItem("siteName", siteName);
  }, []);

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    // console.log("lastFilledComponent", lastFilledComponent);
    if (lastFilledComponent) {
      setFormData({
        orderPreparationTime: lastFilledComponent.orderPreparationTime || "",
        deliveryCharges: lastFilledComponent.deliveryCharges || "",
        stripeFee: lastFilledComponent.stripeFee || "",
        salesTax: lastFilledComponent.salesTax || "",
        companyName: lastFilledComponent.companyName || "",
        companyType: lastFilledComponent.companyType || "",
        ein: lastFilledComponent.ein || "",
        dba: lastFilledComponent.dba || "",
      });
    }
  }, [setFormData]);

  const selectStyle = {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#FFA500", // Change hover color
  };

  const handleShow = () => {
    setOpenModal(true);
  }

  const handleClose = () => {
    setOpenModal(false);
  }

  const handleCloseLaunchModal = () => {
    setLaunchModal(false)
  }

  const handleInputChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLaunchModal(true);
      // oneFormSubmitData(formData, siteName, decodedValue, convertedText);
      // localStorage.clear();
      // setShowForm("thanks");
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };

  const onBackComponent = () => {
    setShowForm("form");
  };
  return (
    <>
      <div className="right-container">
        <div className="right-header">
          <a href="https://getorderbyte.com/" className="logo">
            <img src={Logo} />
          </a>
          <a href="mailto:pizzabox@restogpt.ai">
            <span className="icon">
              <img src={Contact} />
            </span>
          </a>
        </div>
        {decodedValue !== "" && (
          <div className="right-header-text">
            {/* <p className="right-header-text-p">{convertedText}</p> */}
            <a href={url} target="_blank">
              {url}
            </a>
          </div>
        )}
        <div className="right-wrapper">
          <form class="styled-form">
            <div class="form-group">
              <label htmlFor="name">
                9
                <ArrowRightShort />
                Delivery charges <span className="impt">*</span>
              </label>
              <div className="radio-btn-group">
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="deliveryCharges"
                    value="50/50 between restaurant and customer"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={
                      formData.deliveryCharges ===
                      "50/50 between restaurant and customer"
                    }
                  />
                  <span className="radio-text">
                    50/50 between restaurant and customer
                  </span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="deliveryCharges"
                    value="Paid by the restaurant"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={
                      formData.deliveryCharges === "Paid by the restaurant"
                    }
                  />
                  <span className="radio-text">Paid by the restaurant</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="deliveryCharges"
                    value="Paid by the customer"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={
                      formData.deliveryCharges === "Paid by the customer"
                    }
                  />
                  <span className="radio-text">Paid by the customer</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="deliveryCharges"
                    value="Other (indicate any custom split you’d like)"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={
                      formData.deliveryCharges ===
                      "Other (indicate any custom split you’d like)"
                    }
                  />
                  <span className="radio-text">
                    Other (indicate any custom split you’d like)
                  </span>
                </div>
                {formData.deliveryCharges ===
                  "Other (indicate any custom split you’d like)" && (
                  <input
                    type="text"
                    className="input-form"
                    id="otherDeliveryCharges"
                    name="otherDeliveryCharges"
                    value={formData.otherDeliveryCharges}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                )}
              </div>
              <span className="error">{errors.deliveryCharges}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                10
                <ArrowRightShort />
                Order preparation time for pickup and delivery{" "}
                <span className="impt">*</span>
              </label>
              <div className="radio-btn-group">
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="orderPreparationTime"
                    value="15 min"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.orderPreparationTime === "15 min"}
                  />
                  <span className="radio-text">15 min</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="orderPreparationTime"
                    value="20 min"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.orderPreparationTime === "20 min"}
                  />
                  <span className="radio-text">20 min</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="orderPreparationTime"
                    value="25 min"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.orderPreparationTime === "25 min"}
                  />
                  <span className="radio-text">25 min</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="orderPreparationTime"
                    value="Other"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.orderPreparationTime === "Other"}
                  />
                  <span className="radio-text">Other</span>
                </div>
                {formData.orderPreparationTime === "Other" && (
                  <input
                    type="text"
                    className="input-form"
                    id="otherOrderPreparationTime"
                    name="otherOrderPreparationTime"
                    value={formData.otherOrderPreparationTime}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                )}
              </div>
              <span className="error">{errors.orderPreparationTime}</span>
              <span className="error">{errors.otherOrderPreparationTime}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                11
                <ArrowRightShort />
                Who should incur Stripe's credit card processing fee?{" "}
                <span className="impt">*</span>
              </label>
              <div className="radio-btn-group">
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="stripeFee"
                    value="Paid by the restaurant"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.stripeFee === "Paid by the restaurant"}
                  />
                  <span className="radio-text">Paid by the restaurant</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="stripeFee"
                    value="Paid by the customer"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.stripeFee === "Paid by the customer"}
                  />
                  <span className="radio-text">Paid by the customer</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="stripeFee"
                    value="Built into the price"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.stripeFee === "Built into the price"}
                  />
                  <span className="radio-text">Built into the price</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="stripeFee"
                    value="Other (indicate any custom split you’d like)"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={
                      formData.stripeFee ===
                      "Other (indicate any custom split you’d like)"
                    }
                  />
                  <span className="radio-text">
                    Other (indicate any custom split you’d like)
                  </span>
                </div>
                {formData.stripeFee ===
                  "Other (indicate any custom split you’d like)" && (
                  <input
                    type="text"
                    className="input-form"
                    id="otherStripeFee"
                    name="otherStripeFee"
                    value={formData.otherStripeFee}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                )}
              </div>
              <span className="error">{errors.stripeFee}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                12
                <ArrowRightShort />
                Sales tax <span className="impt">*</span>
              </label>
              <div className="radio-btn-group">
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="salesTax"
                    value="8.5%"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.salesTax === "8.5%"}
                  />
                  <span className="radio-text">8.5%</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="salesTax"
                    value="9%"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.salesTax === "9%"}
                  />
                  <span className="radio-text">9%</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="salesTax"
                    value="9.5%"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.salesTax === "9.5%"}
                  />
                  <span className="radio-text">9.5%</span>
                </div>
                <div className="radio-btn">
                  <input
                    type="radio"
                    name="salesTax"
                    value="Other"
                    className="radio-input"
                    onChange={handleInputChangeWithStorageUpdate}
                    checked={formData.salesTax === "Other"}
                  />
                  <span className="radio-text">Other</span>
                </div>
                {formData.salesTax === "Other" && (
                  <input
                    type="text"
                    className="input-form"
                    id="otherSalesTax"
                    name="otherSalesTax"
                    value={formData.otherSalesTax}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                )}
              </div>
              <span className="error">{errors.salesTax}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                13
                <ArrowRightShort />
                Legal company name <span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="companyName"
                name="companyName"
                placeholder="e.g. www.stellas.com"
                value={formData.companyName}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.companyName}</span>
            </div>
            <div class="form-group">
              <label htmlFor="posState">
                14
                <ArrowRightShort />
                Company type
                <span className="impt">*</span>
              </label>
              <select
                className="input-form"
                id="companyType"
                name="companyType"
                onChange={handleInputChangeWithStorageUpdate}
                value={formData.companyType}
              >
                <option value="">Please select</option>
                {companyTypeOption.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              <span className="error">{errors.companyType}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                15
                <ArrowRightShort />
                DBA (if any)
              </label>
              <input
                type="text"
                className="input-form"
                id="dba"
                name="dba"
                // placeholder="e.g. Robert Cooperman"
                value={formData.dba}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.dba}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                16
                <ArrowRightShort />
                EIN <span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="ein"
                name="ein"
                // placeholder="e.g. Robert Cooperman"
                value={formData.ein}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.ein}</span>
            </div>

            <div className="agreement-group">
              <div className="agreement">
                <input
                  type="checkbox"
                  name="agreementCheckbox"
                  id="agreementCheckbox"
                  className="custom-checkbox"
                  onChange={handleCheckboxChange}
                />
                <p>
                  I have read and agree to the{" "}
                  <span onClick={handleShow}>Non-Disclosure Agreement</span>
                </p>
              </div>
              <span className="error">{errors.checkboxChecked}</span>
            </div>

            <div className="btn-form-container">
              <button className="form-btn" onClick={handleSubmitData}>
                Submit
              </button>
              <button className="back-btn" onClick={onBackComponent}>
                Back
              </button>
            </div>
          </form>
        </div>
        <NonDisclosureModal open={openModal} handleClose={handleClose}/>
        <LaunchModal open={openLaunchModal} handleClose={handleCloseLaunchModal}/>
      </div>
    </>
  );
};

export default FormDesktop;
