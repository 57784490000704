import React from "react";
import useWindowResize from "../../hooks/onbording/static/useWindowResize";
import { carousal } from "../../hooks/onbording/static/carousal";
import Carousel from "react-bootstrap/Carousel";
// console.log("carousal", carousal);
const RestoCarousel = () => {
  const { screenWidth } = useWindowResize();
  return (
    <>
      <div className="slider">
        {carousal.length ? (
          <Carousel interval={3000}>
            {screenWidth > 992 ? (
              <Carousel.Item>
              <div className="itemfirst">
                <h3>Hey 👋 <br/><span>Welcome</span> to RestoGPT</h3>
                <p>Maximize orders <span>with AI</span></p>
                <p>Lower costs <span>with AI</span></p>
                <p>Make money <span>with AI</span></p>
              </div>
            </Carousel.Item> 
            ) :null}
            {carousal.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <div className={`item ${item.background}`}>
                    <p dangerouslySetInnerHTML={{ __html: item.benefit }} />
                    <h3 dangerouslySetInnerHTML={{ __html: item.h3 }} />
                    <h4 dangerouslySetInnerHTML={{ __html: item.h4 }} />
                    <div className="grow"></div>
                    <h6>
                      {item.h6 === "" ? null : (<span></span>)}
                      <div dangerouslySetInnerHTML={{ __html: item.h6 }} />
                    </h6>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        ) : null}
      </div>
    </>
  );
};

export default RestoCarousel;
