import React, { useState } from "react";
import Select from "react-dropdown-select";
import { ArrowRightShort } from "react-bootstrap-icons";

const hearAboutOptions = [
  { value: "Email", label: "Email" },
  { value: "Google/Search Engine", label: "Google/Search Engine" },
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "TikTok", label: "TikTok" },
  { value: "Trade Show/Event", label: "Trade Show/Event" },
  { value: "Referral", label: "Referral" },
  { value: "Other", label: "Other" },
];

const HearAbout = ({
  hearAbout,
  setHearAbout,
  hearAboutReferrer,
  setHearAboutReferrer,
  hearAboutOther,
  setHearAboutOther,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next,
}) => {
  let i = 0;
  let placeholder = "";
  const txt = "example of select";
  let speed = 120;
  const myFunction = (event) => {
    handleKeypress(event, current, "");
  };

  function type() {
    placeholder += txt.charAt(i);
    console.log("placeholder", placeholder);
    document
      .getElementById("restaurantType")
      .setAttribute("placeholder", placeholder);
    i++;
    setTimeout(type, speed);
  }
//   React.useEffect(() => {
//     // setTimeout(() => {
//     //   type();
//     // }, 1000);

//     const keyDownHandler = (event) => {
//       if (event.key === "Enter") {
//         event.preventDefault();
//         myFunction(event);
//       }
//     };
//     document.addEventListener("keydown", keyDownHandler);
//     return () => {
//       document.removeEventListener("keydown", keyDownHandler);
//     };
//   }, []);

  return (
    <>
      <div className="form-box">
        <p>
          11
          <ArrowRightShort />
          How did you hear about RestoGPT?{""}
          <span>*</span>
          {/* <img src={UseOnly} /> */}
        </p>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <select
            id="hearAbout"
            className="form-control"
            onChange={(val) => {
              localStorage.setItem("hear_about", val.target.value);
              setHearAbout(val.target.value);
              setErrorMessage("");
            }}
            value={hearAbout}
            onKeyDown={(e) =>handleKeypress(e, current, "")}
          >
            <option disabled={true} value="">
              Select
            </option>
            {hearAboutOptions.map((item, index) => {
              return (
                <option
                  value={item.value}
                  style={{ color: "#fff", backgroundColor: "#7700B0" }}
                  key={item.value}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
          {hearAbout === "Referral" ? (
            <>
              {/* <p>Please write the name of the referrer</p> */}
              <input
                type="text"
                id="referrerId"
                placeholder="Please write the name of the referrer"
                className="form-control"
                value={hearAboutReferrer}
                onChange={(val) => {
                  setHearAboutReferrer(val.target.value);
                  localStorage.setItem("hearAboutReferrer", val.target.value);
                  setErrorMessage("");
                }}
                //   onKeyPress={(e) => handleKeypress(e, current, next)}
              />
            </>
          ) : null}
          {hearAbout === "Other" ? (
            <>
              {/* <p>Please write the name of the referrer</p> */}
              <input
                type="text"
                id="referrerId"
                placeholder="Please write how you heard about us"
                className="form-control"
                value={hearAboutOther}
                onChange={(val) => {
                  setHearAboutOther(val.target.value);
                  localStorage.setItem("hearAboutReferrer", val.target.value);
                  setErrorMessage("");
                }}
                //   onKeyPress={(e) => handleKeypress(e, current, next)}
              />
            </>
          ) : null}
        </form>
        <p className="comment-l" style={{ color: "red" }}>
          {errorMessage}
        </p>
        {/* <p className="comment">* This question is required</p> */}
      </div>
    </>
  );
};

export default HearAbout;
