import React from "react";
import Loading from "../loader/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Logo from "../../../images/onbordinglogo.png";
import Contact from "../../../images/onbording-contact-icon.svg";
import { ArrowRightShort } from "react-bootstrap-icons";
import useUserForm from "../../hooks/onbording/user/new/useInternal";
import useLocalStorage from "../../hooks/onbording/user/new/useLocalStorageInternal";
import {
  deliveryTypeOption,
  posOption,
  preparationTime,
  resTypeOptions,
} from "../../selectOption";
import submitData from "../../hooks/onbording/user/new/internalSubmit";
import LocationSearchInput from "./LocationSearchInput";

const FormDesktop = ({ setShowForm, siteName }) => {
  // const { siteName  } = useParams();
  // console.log("siteName ", siteName );
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData, errors, validateForm] = useUserForm();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorage("formData");

  let decodedValue = "";

  // Get the current URL
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("root")) {
    const encodedValue = currentUrl.split("=")[1];

    // Decode the base64-encoded value
    decodedValue = atob(decodeURIComponent(encodedValue));

    // console.log("decodedValue-----------", decodedValue);
  } else {
    // console.log("No 'root' parameter in the URL.");
  }
  React.useEffect(() => {
    localStorage.setItem("siteName", siteName);
  }, []);

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    // console.log("lastFilledComponent", lastFilledComponent);
    if (lastFilledComponent) {
      setFormData({
        restaurantName: lastFilledComponent.restaurantName || "",
        restaurantType: lastFilledComponent.restaurantType || "",
        restaurantAddress: lastFilledComponent.restaurantAddress || "",
        restaurantWebsite: lastFilledComponent.restaurantWebsite || "",
        color: lastFilledComponent.color || "",
      });
    }
  }, [setFormData]);

  const selectStyle = {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#FFA500", // Change hover color
  };

  const handleInputChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    handleInputChangeWithStorageUpdate({
      target: { name, value: numericValue },
    });
  };

  const setLocation = (locationData) => {
    // Handle the location data in the parent component as needed
    console.log("Location data:", locationData);
    // Example: Update the state with location data
    setFormData((prevData) => ({
      ...prevData,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
    }));
  };

  const handleSubmissionComplete = (loaderStatus) => {
    // Handle loader logic based on loaderStatus
    setLoading(loaderStatus);
    // Additional logic if needed
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    if (validateForm(siteName)) {
      setLoading(true);
      await submitData(
        formData,
        siteName,
        decodedValue,
        handleSubmissionComplete
      );
      localStorage.clear();
      // setShowForm("thanks");
      if (loading === false) {
        toast.success("Data sent successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      setFormData({
        restaurantName: "",
        restaurantType: "",
        restaurantAddress: "",
        restaurantWebsite: "",
        color: "",
      });
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };
  return (
    <>
      <div className="right-container">
        <ToastContainer />
        {loading && <Loading />}
        <div className="right-header">
          <a href="https://getorderbyte.com/" className="logo">
            <img src={Logo} />
          </a>
          <a href="mailto:hello@restogpt.ai">
            <span className="icon">
              <img src={Contact} />
            </span>
          </a>
        </div>
        <div className="right-wrapper">
          <form class="styled-form">
            <div class="form-group">
              <label htmlFor="name">
                1<ArrowRightShort />
                Restaurant name<span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="restaurantName"
                name="restaurantName"
                placeholder="e.g. Stella’s Pizza"
                value={formData.restaurantName}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.restaurantName}</span>
            </div>
            <div class="form-group">
              <label htmlFor="deliveryType">
                2<ArrowRightShort />
                Restaurant type
                <span className="impt">*</span>
              </label>
              <select
                className="input-form"
                id="restaurantType"
                name="restaurantType"
                onChange={handleInputChangeWithStorageUpdate}
                value={formData.restaurantType}
                // style={selectStyle}
              >
                <option value="" style={{ color: "#C8BFE5" }}>
                  Please select
                </option>
                {resTypeOptions.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              <span className="error">{errors.restaurantType}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                3<ArrowRightShort />
                Menu url<span className="impt">*</span>
              </label>
              <input
                type="text"
                className="input-form"
                id="restaurantWebsite"
                name="restaurantWebsite"
                placeholder="e.g. www.stellaspizza.com/menu"
                value={formData.restaurantWebsite}
                onChange={handleInputChangeWithStorageUpdate}
                required
              />
              <span className="error">{errors.restaurantWebsite}</span>
            </div>
            <div class="form-group">
              <label htmlFor="restaurant-address">
                4<ArrowRightShort />
                Restaurant address<span className="impt">*</span>
              </label>
              {/* <input
                type="text"
                className="input-form"
                id="restaurantAddress"
                name="restaurantAddress"
                placeholder="456 Foothill Blvd, La Cañada Flintridge, CA 91011"
                value={formData.restaurantAddress}
                onChange={handleInputChangeWithStorageUpdate}
                required
              /> */}
              <LocationSearchInput
                className="input-form"
                name="restaurantAddress"
                callback={(address) =>
                  handleInputChangeWithStorageUpdate({
                    target: { name: "restaurantAddress", value: address },
                  })
                }
                setLocation={setLocation}
                placeholder="Enter restaurant address"
                restaurantAddress={formData.restaurantAddress}
              />
              <span className="error">{errors.restaurantAddress}</span>
            </div>
            <div class="form-group">
              <label htmlFor="name">
                5<ArrowRightShort />
                Color<span className="impt">*</span>
              </label>
              {/* <input
                type="text"
                className="input-form"
                id="name"
                name="name"
                placeholder="e.g. Robert Cooperman"
                value={formData.color}
                onChange={handleInputChangeWithStorageUpdate}
                required
              /> */}
              <div className="color-box">
                <label className="custom-check">
                  <input
                    type="checkbox"
                    checked={formData.color === "#F9E000"}
                    value={"#F9E000"}
                    name="color"
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                  <span style={{ background: "#F9E000" }}></span>
                </label>
                <label className="custom-check">
                  <input
                    type="checkbox"
                    value={"#FF9900"}
                    name="color"
                    checked={formData.color === "#FF9900"}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                  <span style={{ background: "#FF9900" }}></span>
                </label>
                <label className="custom-check">
                  <input
                    type="checkbox"
                    value={"#AD002A"}
                    name="color"
                    checked={formData.color === "#AD002A"}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                  <span style={{ background: "#AD002A" }}></span>
                </label>
                <label className="custom-check">
                  <input
                    type="checkbox"
                    value={"#80FF00"}
                    name="color"
                    checked={formData.color === "#80FF00"}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                  <span style={{ background: "#80FF00" }}></span>
                </label>
                <label className="custom-check">
                  <input
                    type="checkbox"
                    value={"#000000"}
                    name="color"
                    checked={formData.color === "#000000"}
                    onChange={handleInputChangeWithStorageUpdate}
                  />
                  <span style={{ background: "#000000" }}></span>
                </label>
              </div>
              <span className="error">{errors.color}</span>
            </div>
            <div className="btn-form-container">
              <button className="form-btn" onClick={handleSubmitData}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormDesktop;
