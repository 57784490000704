import React from "react";
import { carousal } from "../../hooks/onbording/static/carousal";
import RestoCarousel from "../common/RestoCarousel";
import Carousel from "react-bootstrap/Carousel";
import MailIcon from "../../../images/mail-icon.png";
import MobileLogo from "../../../images/mobile-logo.png";
import { Container, Row, Col } from "react-bootstrap";

const Index = () => {
  return (
    <>
      <div className="mobile-top-container">
        <Container>
          <Row>
            <Col>
              <div className="mobile-headerr">
                <a href="mailto:hello@restogpt-ai.com">
                  <img src={MobileLogo} />
                </a>
                <p className="mobile-herder-text">THE FUTURE OF ONLINE ORDERING IS AI</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
