import React from "react";
import StartPage from "../mobile/StartPage";
import FormStartPage from "./FormStartPage";
import ThanksPage from "../mobile/thanks";

const Index = ({ siteName, companyInfo }) => {
  console.log("siteName----",siteName);
  const [showPage, setShowPage] = React.useState("formPage");
  const [showText, setShowText] = React.useState(false);
  return (
    <>
      {showPage === "formPage" ? (
        <FormStartPage setShowPage={setShowPage} siteName={siteName} setShowText={setShowText}/>
      ) : null}
    </>
  );
};

export default Index;
