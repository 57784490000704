import { useState } from "react";

const useFormOne = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    restaurantNumber: "",
    restaurantName: "",
    restaurantAddress1: "",
    // restaurantAddress2: "",
    // pin: "",
    restaurantWebsite: "",
    deliveryType: "",
    posState: "",
    otherPosState: "",
    storefrontDomain: "",
    customerDatabase: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    restaurantNumber: "",
    restaurantName: "",
    restaurantAddress1: "",
    // restaurantAddress2: "",
    // pin: "",
    restaurantWebsite: "",
    deliveryTypeState: "",
    posState: "",
    otherPosState: "",
    storefrontDomain: "",
    customerDatabase: "",
  });

  const validateForm = (siteName) => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.name) {
      valid = false;
      newErrors.name = "Name is required";
    } else {
      newErrors.name = "";
    }

    if (!formData.email) {
      valid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      valid = false;
      newErrors.email = "Invalid email format";
    } else {
      newErrors.email = "";
    }

    if (!formData.restaurantNumber) {
      valid = false;
      newErrors.restaurantNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.restaurantNumber)) {
      valid = false;
      newErrors.restaurantNumber = "Phone number must be 10 digits";
    } else {
      newErrors.restaurantNumber = "";
    }

    if (!formData.restaurantAddress1) {
      valid = false;
      newErrors.restaurantAddress1 = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress1 = "";
    }

    // if (!formData.restaurantAddress2) {
    //   valid = false;
    //   newErrors.restaurantAddress2 = "Restaurant address is required";
    // } else {
    //   newErrors.restaurantAddress2 = "";
    // }

    // if (!formData.pin) {
    //   valid = false;
    //   newErrors.pin = "Zip code is required";
    // }
    // else if (!/^\d+$/.test(formData.pin)) {
    //   valid = false;
    //   newErrors.pin = "Zip code must contain only numbers";
    // } else {
    //   newErrors.pin = "";
    // }

    if (!formData.restaurantWebsite) {
      valid = false;
      newErrors.restaurantWebsite = "Restaurant website is required";
    } else {
      newErrors.restaurantWebsite = "";
    }

    if (!formData.deliveryType) {
      valid = false;
      newErrors.deliveryTypeState = "Select delivery type";
    } else {
      newErrors.deliveryTypeState = "";
    }

    if (!formData.posState) {
      valid = false;
      newErrors.posState = "Select POS system";
    } else {
      newErrors.posState = "";
    }

    if (formData.posState === "Other") {
      if (!formData.otherPosState) {
        valid = false;
        newErrors.otherPosState = "POS system is required";
      } else {
        newErrors.otherPosState = "";
      }
    } else {
      // Reset the error for otherPosState if posState is not "Other"
      newErrors.otherPosState = "";
    }

    if (!formData.storefrontDomain) {
        valid = false;
        newErrors.storefrontDomain = "Domain/subdomain is required";
      } else {
        newErrors.storefrontDomain = "";
      }

      // if (!formData.customerDatabase) {
      //   valid = false;
      //   newErrors.customerDatabase = "Choose one option";
      // } else {
      //   newErrors.customerDatabase = "";
      // }

    setErrors(newErrors);
    // console.log("Is form valid?", newErrors);
    return valid;
  };

  return [formData, setFormData, errors, validateForm];
};
export default useFormOne;