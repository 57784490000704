export const carousal = [
    {
        benefit: "Benefit 1", 
        h3: "<span>AI</span> Website and Storefronts",
        h4: "[dynamic, branded – built to sell]",
        h6: "start accepting and delivering direct orders in 24 hours",
        background: "one"
    },
    {
        benefit: "Benefit 2", 
        h3: "<span>Increase</span> Direct Orders",
        h4: "[Website, Google, Yelp, Social Media]",
        // h6: "don't pay commissions and keep your customer data for loyalty",
        h6: "",
        background: ""
    },
    {
        benefit: "Benefit 3", 
        h3: "More <span>Tips</span>",
        h4: "[even on pickup orders]",
        h6: "keep your staff happy and loyal",
        background: "three"
    },
    {
        benefit: "Benefit 4", 
        h3: "<span>Deliver</span> More Orders",
        h4: "[even at rush hour]",
        h6: "no extra costs, overhead or drivers",
        background: "four"
    },
    {
        benefit: "Benefit 5", 
        h3: "Orders on <span>Autopilot</span>",
        h4: "[less staff]",
        h6: "no errors or delays",
        background: "one"
    },
    {
        benefit: "Benefit 6", 
        h3: "<span>Save</span> on Card Processing Fees",
        h4: "[flex options]",
        h6: "extra 3% to the bottom line",
        background: ""
    },
    {
        benefit: "Benefit 7", 
        h3: "No Missed <span>Calls</span>",
        h4: "[even during peak hours]",
        h6: "convert missed calls into revenue",
        background: "three"
    },
    {
        benefit: "Benefit 8", 
        h3: "<span>AI Voice</span> and Text Ordering",
        h4: "[add voice and chat to website ordering]",
        h6: "maximize order generation channels",
        background: "four"
    },
    {
        benefit: "Benefit 9", 
        h3: "<span>Lower</span> Your Payroll",
        h4: "[efficiency with AI and automation]",
        h6: "Hire your 24/7 AI employee",
        background: "one"
    },
    {
        benefit: "Benefit 10", 
        h3: "<span>Reduce</span> 3rd-Party Fees by Half",
        h4: "[DoorDash / UberEats / GrubHub+]",
        h6: "use self-delivery AI dispatch - save 50% in fees",
        background: ""
    },
    {
        benefit: "Benefit 11", 
        h3: "<span>Smart Customer</span> Data Platform",
        h4: "[for promotions and customer engagement]",
        h6: "activate your customer data for order generation",
        background: "three"
    },
    {
        benefit: "Benefit 12", 
        h3: "Marketing and <span>Loyalty</span>",
        h4: "[campaigns to build customer retention on autopilot]",
        h6: "turn order #8745 into a loyal, returning customer",
        background: "four"
    },
    {
        benefit: "Benefit 13", 
        h3: "Generate <span>More Orders</span>",
        h4: "[SMS and email campaigns]",
        h6: "automated, data-driven, targeted customer communication",
        background: "one"
    },
]