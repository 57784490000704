import { useState } from "react";

const useFormTwo = () => {
  const [formData, setFormData] = useState({
    orderPreparationTime: "",
    deliveryCharges: "",
    stripeFee: "",
    salesTax: "",
    companyName: "",
    companyType: "",
    ein: "",
    dba: "",
    otherOrderPreparationTime: "",
    otherDeliveryCharges: "",
    otherStripeFee: "",
    otherSalesTax: "",
  });

  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [errors, setErrors] = useState({
    orderPreparationTime: "",
    deliveryCharges: "",
    stripeFee: "",
    salesTax: "",
    companyName: "",
    companyType: "",
    ein: "",
    dba: "",
    otherOrderPreparationTime: "",
    otherDeliveryCharges: "",
    otherStripeFee: "",
    otherSalesTax: "",
    checkboxChecked: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.orderPreparationTime) {
      valid = false;
      newErrors.orderPreparationTime = "Order preparation time is required";
    } else if (formData.orderPreparationTime === "Other") {
      if (!formData.otherOrderPreparationTime) {
        valid = false;
        newErrors.orderPreparationTime = "Order preparation time is required";
      } else {
        newErrors.orderPreparationTime = "";
      }
    } else {
      newErrors.orderPreparationTime = "";
    }


    if (!formData.deliveryCharges) {
      valid = false;
      newErrors.deliveryCharges = "Delivery charges is required";
    } else if (
      formData.deliveryCharges ===
      "Other (indicate any custom split you’d like)"
    ) {
      if (!formData.otherDeliveryCharges) {
        valid = false;
        newErrors.deliveryCharges = "Delivery charges is required";
      } else {
        newErrors.deliveryCharges = "";
      }
    } else {
      newErrors.deliveryCharges = "";
    }

    if (!formData.stripeFee) {
      valid = false;
      newErrors.stripeFee = "Stripe's credit card processing fee is required";
    } else if (
      formData.stripeFee === "Other (indicate any custom split you’d like)"
    ) {
      if (!formData.otherStripeFee) {
        valid = false;
        newErrors.stripeFee = "Stripe's credit card processing fee is required";
      } else {
        newErrors.stripeFee = "";
      }
    } else {
      newErrors.stripeFee = "";
    }

    if (!formData.salesTax) {
      valid = false;
      newErrors.salesTax = "Sales tax is required";
    } else if (formData.salesTax === "Other") {
      if (!formData.otherSalesTax) {
        valid = false;
        newErrors.salesTax = "Sales tax is required";
      } else {
        newErrors.salesTax = "";
      }
    } else {
      newErrors.salesTax = "";
    }

    if (!formData.companyName) {
        valid = false;
        newErrors.companyName = "Legal company name is required";
      } else {
        newErrors.companyName = "";
      }
  
      if (!formData.companyType) {
        valid = false;
        newErrors.companyType = "Company type is required";
      } else {
        newErrors.companyType = "";
      }
  
      if (!formData.ein) {
        valid = false;
        newErrors.ein = "EIN is required";
      } else {
        newErrors.ein = "";
      }

      if (!checkboxChecked) {
        valid = false;
        newErrors.checkboxChecked = "You must agree to the Non-Disclosure Agreement";
      } else {
        newErrors.checkboxChecked = "";
      }

    // Add similar validation for other fields

    setErrors(newErrors);
    return valid;
  };

  return [formData, setFormData, errors, validateForm, checkboxChecked, setCheckboxChecked];
};

export default useFormTwo;
