import React from "react";
import NonDisclosureModal from "./nonDisclosureModal";
import Logo from "../../../images/onbordinglogo.png";
import Contact from "../../../images/onbording-contact-icon.svg";
import useCompanyForm from "../../hooks/onbording/user/new/useCompanyForm";
import useLocalStorageCompany from "../../hooks/onbording/user/new/useLocalStorageCompany";
import companySubmitData from "../../hooks/onbording/user/new/companySubmitData";
import { companyTypeOption } from "../../selectOption";

const CompanyInfo = ({ setShowForm, setShowText }) => {
  const [
    formData,
    setFormData,
    errors,
    validateForm,
    checkboxChecked,
    setCheckboxChecked,
  ] = useCompanyForm();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorageCompany("formDataCompany");

    const [openModal, setOpenModal] = React.useState(false);

    const handleShow = () => {
      setOpenModal(true);
    }

    const handleClose = () => {
      setOpenModal(false);
    }

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    if (lastFilledComponent) {
      setFormData({
        companyName: lastFilledComponent.companyName || "",
        companyType: lastFilledComponent.companyType || "",
        ein: lastFilledComponent.ein || "",
        dba: lastFilledComponent.dba || "",
        companyAddress: lastFilledComponent.companyAddress || "",
        phone: lastFilledComponent.phone || "",
        ownerFirstName: lastFilledComponent.ownerFirstName || "",
        ownerLastName: lastFilledComponent.ownerLastName || "",
        ownerPhone: lastFilledComponent.ownerPhone || "",
        ownerEmail: lastFilledComponent.ownerEmail || "",
        bankName: lastFilledComponent.bankName || "",
        bankAddress: lastFilledComponent.bankAddress || "",
      });
    }
  }, [setFormData]);

  const handleChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    handleChangeWithStorageUpdate({
      target: { name, value: numericValue },
    });
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      companySubmitData();
      setShowForm("thanks");
      setShowText(true);
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
    }
  };

  const onBackComponent = () => {
    setShowForm("delivery");
  };

  return (
    <>
      <div className="right-container">
        <div className="right-header">
          <a href="https://getorderbyte.com/" className="logo">
            <img src={Logo} />
          </a>
          <a href="mailto:hello@restogpt.ai">
            <span className="icon">
              <img src={Contact} />
            </span>
          </a>
        </div>
        <div className="head-title">
          <h3>Company Information</h3>
        </div>
        <div className="right-wrapper">
          <form class="styled-form">
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Legal company name <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="companyName"
                    name="companyName"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.companyName}
                    onChange={handleChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.companyName}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="form-company-group">
                <div className="input-grouped">
                  <label htmlFor="name" className="form-label">
                    Company type <span className="impt">*</span>
                  </label>
                  <div className="company-single">
                    <select
                      className="input-form"
                      id="companyType"
                      name="companyType"
                      onChange={handleChangeWithStorageUpdate}
                      value={formData.companyType}
                      // style={selectStyle}
                    >
                      <option value="" style={{ color: "#C8BFE5" }}></option>
                      {companyTypeOption.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    <span className="error">{errors.companyType}</span>
                  </div>
                </div>
                <div className="input-grouped name-single">
                  <label htmlFor="name" className="form-label">
                    EIN <span className="impt">*</span>
                  </label>
                  <div className="company-single">
                    <input
                      type="text"
                      className="input-form"
                      id="ein"
                      name="ein"
                      // placeholder="e.g. Robert Cooperman"
                      value={formData.ein}
                      onChange={handleChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.ein}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  DBA (if any)
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="dba"
                    name="dba"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.dba}
                    onChange={handleChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.dba}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Company address <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="companyAddress"
                    name="companyAddress"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.companyAddress}
                    onChange={handleChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.companyAddress}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Phone number <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="phone"
                    name="phone"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.phone}
                    onChange={handlePhoneInputChange}
                    required
                  />
                  <span className="error">{errors.phone}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Owner's name <span className="impt">*</span>
                </label>
                <div className="name-group">
                  <div className="name-single">
                    <input
                      type="text"
                      className="input-form"
                      id="ownerFirstName"
                      name="ownerFirstName"
                      placeholder="First Name"
                      value={formData.ownerFirstName}
                      onChange={handleChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.ownerFirstName}</span>
                  </div>
                  <div className="name-single">
                    <input
                      type="text"
                      className="input-form"
                      id="ownerLastName"
                      name="ownerLastName"
                      placeholder="Last Name"
                      value={formData.ownerLastName}
                      onChange={handleChangeWithStorageUpdate}
                      required
                    />
                    <span className="error">{errors.ownerLastName}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Owner's phone number <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="ownerPhone"
                    name="ownerPhone"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.ownerPhone}
                    onChange={handlePhoneInputChange}
                    required
                  />
                  <span className="error">{errors.ownerPhone}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Owner's email <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="ownerEmail"
                    name="ownerEmail"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.ownerEmail}
                    onChange={handleChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.ownerEmail}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Bank name <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="bankName"
                    name="bankName"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.bankName}
                    onChange={handleChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.bankName}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div className="input-grouped">
                <label htmlFor="name" className="form-label">
                  Bank address <span className="impt">*</span>
                </label>
                <div className="field-group">
                  <input
                    type="text"
                    className="input-form"
                    id="bankAddress"
                    name="bankAddress"
                    // placeholder="e.g. Robert Cooperman"
                    value={formData.bankAddress}
                    onChange={handleChangeWithStorageUpdate}
                    required
                  />
                  <span className="error">{errors.bankAddress}</span>
                </div>
              </div>
            </div>
            <div className="agreement-group">
              <div className="agreement">
                <input
                  type="checkbox"
                  name="agreementCheckbox"
                  id="agreementCheckbox"
                  className="custom-checkbox"
                  onChange={handleCheckboxChange}
                />
                <p>
                  I have read and agree to the{" "}
                  <span onClick={handleShow}>
                    Non-Disclosure Agreement
                  </span>
                </p>
              </div>
              <span className="error">{errors.checkboxChecked}</span>
            </div>
            <div className="btn-form-container">
              <button className="form-btn" onClick={onSubmitForm}>
                Submit
              </button>
              <button className="back-btn" onClick={onBackComponent}>
                Back
              </button>
            </div>
          </form>
        </div>
        <NonDisclosureModal open={openModal} handleClose={handleClose}/>
      </div>
    </>
  );
};

export default CompanyInfo;
