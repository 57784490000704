import React from "react";
import HttpClient from "../utils/httpClient";
import { ArrowRightShort } from "react-bootstrap-icons";
import uploadIcon from "../images/upload-icon.svg";
const ResLogo = ({
  restaurantLogo,
  setRestaurantLogo,
  restaurantLogoRequired,
  setRestaurantLogoRequired,
  restaurantLogoName,
  setRestaurantLogoName,
  errorMessage,
  setErrorMessage,
  handleKeypress,
  current,
  next
}) => {
    const myFunction = (event) => {
        handleKeypress(event,current,next)
      };
    
      React.useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            myFunction(event);
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

  const onLogoUpload = (e) => {
    uploadLogo(e.target.files[0]);
    setRestaurantLogoRequired(true);
    setErrorMessage("");
    setRestaurantLogoName(e.target.files[0].name);
  };
  const uploadLogo = async (files) => {
    // console.log("files", files);
    let data = new FormData();
    data.append("gpt", files);
    let result = await HttpClient.upload("upload/", "POST", data);
    if (result) {
      setRestaurantLogo(result.fileLinks[0]);
      localStorage.setItem("logo",result.fileLinks[0])
      setRestaurantLogoRequired(false);
    } else {
      setRestaurantLogo("");
    }
  };

  return (
    <>
      <div className="form-box">
        <p>
          7<ArrowRightShort />
          {/* 6<ArrowRightShort /> */}
          Upload restaurant logo [optional]
        </p>
        <label htmlFor="logoUpload" className="fileupload">
          <input
            type="file"
            id="logoUpload"
            onChange={onLogoUpload}
            accept="image/png, image/jpeg, image/jpg, image/webp, image/pdf, image/eps"
            onKeyDown={(e) => handleKeypress(e, current, next)}
          />
          <img src={uploadIcon} />
          <span>{restaurantLogoName ? restaurantLogoName : "filename"}</span>
        </label>

        {/* <p className="comment-l" style={{ color: "red" }}>
              {errorMessage}
            </p> */}
        {restaurantLogoRequired ? (
          <p className="comment-l" style={{ color: "red" }}>
            Please Wait Until Logo is Uploaded
          </p>
        ) : null}
      </div>
    </>
  );
};

export default ResLogo;
