import React from "react";
import HttpClient from "../utils/httpClient";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { newsData } from "./newsData";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Loader from "../component/Loader";
import NewsHead from "./newsHead";
import Footer from "../footer/index";
import "./news.css";

const Index = () => {
  const navigate = useNavigate();
  const [allNewsData, setAllNewsData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    setLoading(true);
    let data = {
      limit:0,
      offset:0
    }
    let result = await HttpClient.requestDataNews(
      "get",
      "POST",
      data
    );
    // console.log("news data ", result);
    if (result) {
      setLoading(false);
      const sortedNewsPosts = result.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      const filter = sortedNewsPosts.filter((item) => item.isPizzaBox === false);
      setAllNewsData(filter);
    }
  };

  const handleContinue = (link) => {
    window.open(link, '_blank');
    // navigate(`/news-detail/${id}`);
  };
  return (
    <>
    {loading ? (
        <Loader />
      ) : (
        <div className="news-wrapper">
        <NewsHead />
        <div className="news-wrapper-content">
          <div className="news-container">
            <Container>
              <Row>
                {allNewsData.map((item, index) => {
                  return (
                    <Col xs={12} sm={6} style={{marginBottom: "28px"}}>
                      <Card
                      key={item.id}
                        style={{
                          // marginBottom: "35px",
                          border: "2px solid #EEE",
                          height: "100%",
                        }}
                      >
                        <Card.Body>
                          <div className="card-name">{item.author_name}</div>
                          <Card.Title
                            className="card-title"
                            onClick={() => handleContinue(item.link)}
                          >
                            {item.title}
                          </Card.Title>
                          <Card.Text className="time">
                          {moment.utc(item.date).format("ll")}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
      )}  
    </>
  );
};

export default Index;
